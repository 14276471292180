import React, { memo, useMemo } from 'react';

import { formatGraduateWorkingStatus, formatLocaleDate } from '../../../../../contracts/utils';
import { useContractDetail } from '../../../../provider/context';
import { RowData, RowDataBlockHeader, RowDataBlockTitle } from '../row-data/row-data.styled';
import {
  DetailLabel,
  DetailLabelIndented,
  DetailLabelStrong,
  DetailValue,
} from './contract-details.styled';

type StatusVariant = 'success' | 'warning' | 'error';

const ContractDetailsWorkData = () => {
  const { contractState } = useContractDetail();

  const workStatusVariant = useMemo<StatusVariant>(() => {
    const status = contractState?.data?.graduate.status;

    if (!status || status === 'notDeclared') {
      return 'error';
    }
    if (['unemployment', 'changingEmployment'].includes(status)) {
      return 'warning';
    }
    return 'success';
  }, [contractState]);

  if (!contractState.data) {
    return null;
  }

  const { data } = contractState;

  return (
    <div>
      <RowDataBlockHeader>
        <RowDataBlockTitle>Datos de empleo</RowDataBlockTitle>
      </RowDataBlockHeader>
      <RowData>
        <DetailLabel>Situación laboral</DetailLabel>
        <DetailValue variant={workStatusVariant}>
          {formatGraduateWorkingStatus('workingStatus', data.graduate.status)}
        </DetailValue>
      </RowData>
      {data.graduate.jobs.map((job) => (
        <div key={`${job.companyName}-${job.startDate}`}>
          <RowData>
            <DetailLabelStrong>Empleador</DetailLabelStrong>
            <DetailValue>{job.companyName}</DetailValue>
          </RowData>
          <RowData>
            <DetailLabelIndented>Ingreso bruto declarado</DetailLabelIndented>
            <DetailValue>{`${job.incomeCurrency} ${job.incomeAmount}`}</DetailValue>
          </RowData>
          <RowData>
            <DetailLabelIndented>Fecha de ingreso</DetailLabelIndented>
            <DetailValue>{formatLocaleDate('jobStartDate', job.startDate)}</DetailValue>
          </RowData>
          <RowData>
            <DetailLabelIndented>Tipo de empleo</DetailLabelIndented>
            <DetailValue>{job.typeOfService}</DetailValue>
          </RowData>
        </div>
      ))}
    </div>
  );
};

export default memo(ContractDetailsWorkData);
