import React from 'react';

import { ContentWrapper } from '../../components/layout/layout.styled';
import { PageTitle } from '../../components/page-title/page-title.styled';

import { ROUTES } from '../../router/routes';
import { ExternalLink, InternalLink, RootList, RootListItem } from './root.styled';

const RootPage = () => (
  <ContentWrapper>
    <PageTitle>Módulos</PageTitle>
    <RootList>
      <RootListItem>
        <InternalLink to={ROUTES.CONTRACTS}>Contratos</InternalLink>
      </RootListItem>
      <RootListItem>
        <ExternalLink href={ROUTES.DDJJ}>DDJJ</ExternalLink>
      </RootListItem>
    </RootList>
  </ContentWrapper>
);

export default RootPage;
