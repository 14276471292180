import React from 'react';

import { SvgIcon } from './svgIcon.styled';

const ClearIcon = () => (
  <SvgIcon focusable="false" viewBox="0 0 17 16" aria-hidden="true">
    <circle cx="8.5" cy="8" r="8" fill="#4C1992" />
    <path
      d="M7.768 7.67L5.644 4.628H7.075L8.578 6.986L10.063 4.628H11.44L9.352 7.67L11.665 11H10.225L8.542 8.309L6.868 11H5.5L7.768 7.67Z"
      fill="#C9A8FF"
    />
  </SvgIcon>
);

export default ClearIcon;
