import React, { ChangeEvent, HTMLInputTypeAttribute, useCallback } from 'react';

import { Input, InputContainer, InputError, InputLabel, InputWrapper } from './text-input.styled';

export interface SearchInputShape {
  id: string;
  placeholder: string;
  type?: HTMLInputTypeAttribute;
}

export interface SearchInputProps extends SearchInputShape {
  onChange: (id: string, value: string) => void;
  value: string;
  label?: string;
  error?: string;
}

const TextInput = ({
  id,
  label,
  onChange,
  value,
  placeholder,
  type = 'text',
  error,
}: SearchInputProps) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(id, e.target.value);
    },
    [onChange],
  );

  return (
    <InputContainer>
      {label && <InputLabel htmlFor={id}>{label}</InputLabel>}

      <InputWrapper hasError={Boolean(error)} hasLabel={Boolean(label)}>
        <Input
          id={id}
          type={type}
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
        />
      </InputWrapper>

      {error && <InputError>{error}</InputError>}
    </InputContainer>
  );
};

export default TextInput;
