import React, { memo } from 'react';

import {
  formatGraduateBillingSystem,
  formatGraduatePaymentMethod,
} from '../../../../../contracts/utils';
import { useContractDetail } from '../../../../provider/context';
import EditDrawerFinancialData from '../edit-drawer/edit-drawer-financial-data';
import RowDataExpandable from '../row-data/row-data-expandable';
import { RowData, RowDataBlockHeader, RowDataBlockTitle } from '../row-data/row-data.styled';
import { DetailLabel, DetailLabelIndented, DetailValue } from './contract-details.styled';

const ContractDetailsTaxData = () => {
  const { contractState } = useContractDetail();

  if (!contractState.data) {
    return null;
  }

  return (
    <div>
      <RowDataBlockHeader>
        <RowDataBlockTitle>Datos financieros</RowDataBlockTitle>
        <EditDrawerFinancialData title="Editar datos financieros" />
      </RowDataBlockHeader>
      <RowData>
        <DetailLabel>Tax ID</DetailLabel>
        <DetailValue>{contractState.data.graduate.taxId}</DetailValue>
      </RowData>
      <RowData>
        <DetailLabel>Método de pago</DetailLabel>
        <DetailValue>
          {formatGraduatePaymentMethod('paymentMethod', contractState.data.graduate.paymentMethod)}
        </DetailValue>
      </RowData>
      <RowData>
        <DetailLabel>Sistema de facturación</DetailLabel>
        <DetailValue>
          {formatGraduateBillingSystem('billingSystem', contractState.data.graduate.billingSystem)}
        </DetailValue>
      </RowData>
      <RowDataExpandable label={<DetailLabel>Residencia fiscal</DetailLabel>}>
        <RowData>
          <DetailLabelIndented>Pais</DetailLabelIndented>
          <DetailValue>{contractState.data.graduate.taxAddressCountry}</DetailValue>
        </RowData>
        <RowData>
          <DetailLabelIndented>Código postal</DetailLabelIndented>
          <DetailValue>{contractState.data.graduate.taxAddressZipCode}</DetailValue>
        </RowData>
        <RowData>
          <DetailLabelIndented>Calle</DetailLabelIndented>
          <DetailValue>{contractState.data.graduate.taxAddressStreetName}</DetailValue>
        </RowData>
        <RowData>
          <DetailLabelIndented>Número</DetailLabelIndented>
          <DetailValue>{contractState.data.graduate.taxAddressStreetNumber}</DetailValue>
        </RowData>
      </RowDataExpandable>
    </div>
  );
};

export default memo(ContractDetailsTaxData);
