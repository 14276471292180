import React, { ReactNode } from 'react';

import { Backdrop, CloseButton, DrawerContainer } from './drawer.styled';

export interface DrawerProps {
  isOpen?: boolean;
  onClose?: () => void;
  children?: ReactNode;
  className?: string;
}

const Drawer = ({ isOpen, onClose, children, className }: DrawerProps) => {
  return (
    <>
      <Backdrop isOpen={isOpen} onClick={onClose} data-testid="drawer-backdrop" />
      <DrawerContainer className={className} isOpen={isOpen} data-testid="drawer">
        <CloseButton size="large" icon="close" onClick={onClose} />
        {children}
      </DrawerContainer>
    </>
  );
};

export default Drawer;
