import React, { ReactElement, memo, useState } from 'react';

import { TabItem, TabLabel, TabList } from './tabs.styled';

interface Props {
  initialTab: string;
  tabs: {
    label: string;
    disabled?: boolean;
    content: ReactElement;
  }[];
}

const Tabs = ({ tabs, initialTab }: Props) => {
  const [active, setActive] = useState(initialTab);

  return (
    <>
      <TabList role="tablist">
        {tabs.map((tab) => (
          <TabItem key={tab.label} role="tab">
            <TabLabel
              active={tab.label === active}
              disabled={tab.disabled ?? false}
              onClick={() => setActive(tab.label)}
            >
              {tab.label}
            </TabLabel>
          </TabItem>
        ))}
      </TabList>
      {tabs.map((tab) =>
        tab.label === active ? (
          <div key={tab.label} role="tabpanel">
            {tab.content}
          </div>
        ) : undefined,
      )}
    </>
  );
};

export default memo(Tabs);
