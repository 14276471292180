import React from 'react';

import { ContractsLink } from './components/contracts-link/contracts-link';

import {
  ContractActivationReason,
  ContractState,
  GraduateBillingSystem,
  GraduateDeclarationStatus,
  GraduateDeclarationType,
  GraduatePaymentMethod,
  GraduateWorkingStatus,
} from '../../services/api/types';

export const stateMap: Record<ContractState, string> = {
  active: 'Activo',
  finished: 'Finalizado',
  inactive: 'Inactivo',
};

export const formatContractState = (_id: string | number, value: unknown) => {
  return stateMap[String(value) as ContractState] || '';
};

export const contractReasonMap: Record<ContractActivationReason, string> = {
  graduation: 'Graduado',
  withdraw: 'Baja',
};

export const formatContractActivationReason = (_id: string | number, value: unknown) => {
  return contractReasonMap[String(value) as ContractActivationReason] || '';
};

export const formatLocaleDate = (_id: string | number, date: string, localeFormat = 'es-AR') => {
  // TimeZone forced to UTC to avoid GMT-X error
  return new Date(date).toLocaleDateString(localeFormat, { timeZone: 'UTC' });
};

export const graduateWorkingStatusMap: Record<GraduateWorkingStatus, string> = {
  notDeclared: 'No declarado',
  unemployment: 'Sin empleo',
  employment: 'Empleado',
  multiemployment: 'Multiempleo',
  changingEmployment: 'Cambiando de empleo',
};

export const formatGraduateWorkingStatus = (_id: string | number, value: unknown) => {
  return graduateWorkingStatusMap[String(value) as GraduateWorkingStatus] || '';
};

export const graduateBillingSystemMap: Record<GraduateBillingSystem, string> = {
  colppy: 'Colppy',
  stripe: 'Stripe',
};

export const formatGraduateBillingSystem = (_id: string | number, value: unknown) => {
  return graduateBillingSystemMap[String(value) as GraduateBillingSystem] || '';
};

export const graduatePaymentMethodMap: Record<GraduatePaymentMethod, string> = {
  BankAccount: 'Cuenta bancaria',
  CreditCard: 'Tarjeta de crédito',
  DebitCard: 'Tarjeta de débito',
  Paypal: 'Paypal',
};

export const formatGraduatePaymentMethod = (_id: string | number, value: unknown) => {
  return graduatePaymentMethodMap[String(value) as GraduatePaymentMethod] || '';
};

export const graduateDeclarationStatusMap: Record<GraduateDeclarationStatus, string> = {
  presented: 'Presentada',
  resubmitted: 'Presentada corregida',
  inReview: 'En revisión',
  accepted: 'Aceptada',
  acceptedWithPendings: 'Aceptada con pendientes',
  acceptedWithCorrections: 'Aceptada con corrección',
  rejected: 'Rechazada',
  empty: 'Pendiente',
  draft: 'Pendiente',
};

export const formatGraduateDeclarationStatus = (_id: string | number, value: unknown) => {
  return graduateDeclarationStatusMap[String(value) as GraduateDeclarationStatus] || '';
};

export const graduateDeclarationTypeMap: Record<GraduateDeclarationType, string> = {
  unemployment: 'Baja de empleo',
  stayEmployedIncomeUnchanged: 'Mantiene empleo (mismo salario)',
  stayEmployedIncomeChanged: 'Mantiene empleo (nuevo salario)',
  changeEmploymentUnemployment: 'Cambio de empleo (baja)',
  changeEmploymentNewEmployment: 'Cambio de empleo (alta)',
  newEmployment: 'Nuevo empleo',
  stayUnemployment: 'Desempleado',
};

export const formatGraduateDeclarationType = (_id: string | number, value: unknown) => {
  return graduateDeclarationTypeMap[String(value) as GraduateDeclarationType] || '';
};

export const formatContractGraduateName = (id: string | number, value: unknown) => {
  return <ContractsLink id={String(id)} value={value as string} />;
};
