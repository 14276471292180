import React, { memo, useCallback, useEffect } from 'react';

import Pagination from '../../../../components/pagination/pagination';
import Table from '../../../../components/table/table';
import { Columns } from '../../../../components/table/types';

import {
  formatGraduateDeclarationStatus,
  formatGraduateDeclarationType,
} from '../../../contracts/utils';
import { useContractDetail } from '../../provider/context';
import { ContractDetailContentContainer } from './contract-detail-content.styled';

const tableColumns: Columns = [
  { id: 'period', header: 'Período' },
  { id: 'type', header: 'Tipo de declaración', format: formatGraduateDeclarationType },
  { id: 'incomeAmount', header: 'Monto' },
  { id: 'incomeCurrency', header: 'Moneda' },
  { id: 'companyName', header: 'Empleador' },
  { id: 'status', header: 'Estado', format: formatGraduateDeclarationStatus },
];

const ContractDetailContentDeclarations = () => {
  const { getGraduateDeclarations, graduateDeclarationsState } = useContractDetail();

  useEffect(() => {
    getGraduateDeclarations();
  }, []);

  const onChangePage = useCallback((pagination: { page: number; pageSize: number }) => {
    getGraduateDeclarations(pagination);
  }, []);

  return (
    <ContractDetailContentContainer>
      <Table columns={tableColumns} data={graduateDeclarationsState.data?.data} />

      {graduateDeclarationsState.data && (
        <Pagination
          page={graduateDeclarationsState.data.page}
          pageSize={graduateDeclarationsState.data.pageSize}
          totalCount={graduateDeclarationsState.data.totalCount}
          onChange={onChangePage}
        />
      )}
    </ContractDetailContentContainer>
  );
};

export default memo(ContractDetailContentDeclarations);
