import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ContractLink = styled(Link)`
  font-weight: ${({ theme }) => theme.fontWeights.heavy};
  text-decoration: none;

  &,
  &:active,
  &:visited,
  &:hover,
  &:link {
    color: ${({ theme }) => theme.colors.purple.main};
  }

  &:hover {
    text-decoration: underline;
  }
`;
