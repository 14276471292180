import { useAuth } from '../../providers/auth';
import useFetch from '../useFetch';
import api from './api';
import { Contract } from './types';

type GetContractByIdResponse = Contract;

const useGetContractById = () => {
  const { getAccessToken } = useAuth();
  return useFetch(async (id: number) => {
    const accessToken = await getAccessToken();

    const res = await api.get<GetContractByIdResponse>(`/contract/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return res.data;
  });
};

export default useGetContractById;
