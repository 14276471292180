import React, { memo } from 'react';

import { useContractDetail } from '../../../../provider/context';
import { RowData, RowDataBlockHeader, RowDataBlockTitle } from '../row-data/row-data.styled';
import { DetailLabel, DetailValue } from './contract-details.styled';

const ContractDetailsPersonalData = () => {
  const { contractState } = useContractDetail();

  if (!contractState.data) {
    return null;
  }

  return (
    <div>
      <RowDataBlockHeader>
        <RowDataBlockTitle>Datos personales</RowDataBlockTitle>
      </RowDataBlockHeader>
      <RowData>
        <DetailLabel>E-mail</DetailLabel>
        <DetailValue>{contractState.data.graduate.email}</DetailValue>
      </RowData>
      <RowData>
        <DetailLabel>E-mail secundario</DetailLabel>
        <DetailValue>{contractState.data.graduate.secondaryEmail}</DetailValue>
      </RowData>
    </div>
  );
};

export default memo(ContractDetailsPersonalData);
