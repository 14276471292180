const theme = {
  colors: {
    black: '#000000',
    white: '#FFFFFF',
    gray: {
      background: '#F8F8F8',
      layover: '#000000BF',
      placeholder: '#464646',
      dark: '#333333',
      medium: '#757575',
      main: '#E5E5E5',
      light: '#F4F4F4',
      lighter: '#F9F9F9',
    },
    yellow: {
      light: '#FFEE581A',
      main: '#FFEE58',
      dark: '#FFC700',
    },
    violet: {
      light: '#C9A8FF',
      main: '#652CB3',
      dark: '#4C1992',
    },
    purple: {
      main: '#99009F',
    },
    red: {
      main: '#FF2600',
      light: '#EF4444',
    },
    warning: '#FF9158',
    success: '#159600',
  },
  fontFamily: '"Avenir", sans-serif;',
  fontSizes: {
    normal: '12px',
    regular: '14px',
    medium: '16px',
    large: '20px',
    xlarge: '24px',
    title: '32px',
  },
  fontWeights: {
    black: 900,
    heavy: 700,
    medium: 500,
    regular: 400,
    book: 300,
    light: 100,
  },
  radius: {
    none: '0',
    xsmall: '2px',
    small: '4px',
    regular: '6px',
    chip: '42px',
    circle: '50%',
  },
  spacing: {
    none: '0',
    xxxsmall: '3px',
    xxsmall: '8px',
    xsmall: '10px',
    small: '12px',
    normal: '14px',
    regular: '16px',
    large: '20px',
    xlarge: '24px',
    xxlarge: '32px',
    action: '42px',
    xxxlarge: '48px',
    big: '64px',
    gigant: '90px',
    headerHolder: '71px',
  },
  zIndex: {
    appBar: 2,
    sideBar: 2,
    overlay: 3,
    modal: 4,
    snackbar: 5,
  },
};

export default theme;
