import { useAuth } from '../../providers/auth';
import useFetch from '../useFetch';
import api from './api';
import { GraduateDeclarations } from './types';

export interface GetDeclarationsResponse {
  page: number;
  pageSize: number;
  totalCount: number;
  data: GraduateDeclarations[];
}

const useGetGraduateDeclarations = () => {
  const { getAccessToken } = useAuth();
  return useFetch(async (id: number, page?: number) => {
    const accessToken = await getAccessToken();
    const res = await api.get<GetDeclarationsResponse>(`/graduate/${id}/declarations`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        page: page ?? 0,
      },
    });

    return res.data;
  });
};

export default useGetGraduateDeclarations;
