import styled from 'styled-components';

export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  height: 100vh;
`;

export const LayoutContainer = styled.main`
  display: flex;
  flex: 1;
  max-height: calc(100vh - ${({ theme }) => theme.spacing.headerHolder});
  overflow: hidden;
`;

export const LayoutContent = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray.background};
  margin-left: ${({ theme }) => theme.spacing.big};

  @media (min-width: 900px) {
    margin-left: 0;
  }
`;

export const ContentWrapper = styled.article`
  overflow-y: auto;
`;

export const LowerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  padding: ${({ theme }) => `0 ${theme.spacing.large}`};

  @media (min-width: 1150px) {
    padding: 0;
    margin: 0 auto;
    max-width: 870px;
    width: 100%;
  }
`;
