import React, { memo } from 'react';

import {
  formatContractActivationReason,
  formatContractState,
  formatLocaleDate,
} from '../../../../../contracts/utils';
import { useContractDetail } from '../../../../provider/context';
import EditDrawerContractData from '../edit-drawer/edit-drawer-contract-data';
import { RowData, RowDataBlockHeader, RowDataBlockTitle } from '../row-data/row-data.styled';
import { DetailLabel, DetailValue } from './contract-details.styled';

const ContractDetailsContractData = () => {
  const { contractState } = useContractDetail();

  if (!contractState.data) {
    return null;
  }

  return (
    <div>
      <RowDataBlockHeader>
        <RowDataBlockTitle>Datos de contrato</RowDataBlockTitle>
        <EditDrawerContractData title="Editar datos de contrato" />
      </RowDataBlockHeader>
      <RowData>
        <DetailLabel>Tipo de contrato</DetailLabel>
        <DetailValue>{contractState.data.version}</DetailValue>
      </RowData>
      <RowData>
        <DetailLabel>Estado de contrato</DetailLabel>
        <DetailValue>{formatContractState('state', contractState.data.state)}</DetailValue>
      </RowData>
      <RowData>
        <DetailLabel>Motivo de activación</DetailLabel>
        <DetailValue>
          {formatContractActivationReason('reason', contractState.data.activationReason)}
        </DetailValue>
      </RowData>
      <RowData>
        <DetailLabel>Fecha de activación</DetailLabel>
        <DetailValue>{formatLocaleDate('date', contractState.data.activationDate)}</DetailValue>
      </RowData>
    </div>
  );
};

export default memo(ContractDetailsContractData);
