import React from 'react';

import { CellLoader } from './table.styled';

interface Props {
  columnsLength: number;
  rowsLength?: number;
}

const TableBodyLoader = ({ columnsLength, rowsLength = 5 }: Props) => {
  const cells = Array.from({ length: columnsLength }, (_e, i) => <CellLoader key={i} />);

  return (
    <tbody>
      {Array.from({ length: rowsLength }, (_e, i) => (
        <tr key={i}>{cells}</tr>
      ))}
    </tbody>
  );
};

export default React.memo(TableBodyLoader);
