import styled from 'styled-components';

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: ${({ theme }) => theme.spacing.normal};
  font-size: ${({ theme }) => theme.fontSizes.regular};
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacing.small} 0;
`;

export const PaginationIndicator = styled.span`
  & span {
    font-weight: ${({ theme }) => theme.fontWeights.black};
  }
`;
