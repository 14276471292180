import React from 'react';

import { SvgIcon } from './svgIcon.styled';

const MoreIcon = () => (
  <SvgIcon focusable="false" viewBox="0 0 14 14" aria-hidden="true">
    <path
      d="M5.768 5.504L5.768 0.224L8.216 0.224V5.504H13.496V7.952H8.216L8.216 13.232H5.768V7.952H0.488V5.504H5.768Z"
      fill="black"
    />
  </SvgIcon>
);

export default MoreIcon;
