import React from 'react';

import { useAuth } from '../../providers/auth';
import CircleButton from '../button/circle/circle';
import HenryLogo from '../logo/logo';
import { AppName, HeaderActions, HeaderAppName, HeaderContainer } from './header.styled';

const Header = () => {
  const { user, doLogout } = useAuth();
  const username = user ? `${user.given_name} ${user.family_name}` : '';

  return (
    <HeaderContainer>
      <HeaderAppName>
        <HenryLogo />
        <AppName>Collections</AppName>
      </HeaderAppName>
      <HeaderActions>
        {username}
        <CircleButton onClick={doLogout} icon="logout" variant="secondary" />
      </HeaderActions>
    </HeaderContainer>
  );
};

export default Header;
