import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import CircleButton from '../../../../components/button/circle/circle';

import { useContractDetail } from '../../provider/context';
import {
  ContractDetailContent,
  ContractDetailHeaderWrapper,
  ContractDetailHighlightDescription,
  ContractDetailHighlightTitle,
  ContractDetailHighlights,
  ContractDetailTitle,
} from './contract-detail-header.styled';

const ContractDetailHeader = () => {
  const navigate = useNavigate();
  const { contractState } = useContractDetail();

  const fullName = contractState.data?.graduate.fullName ?? 'Cargando contrato...';
  const jobs = contractState.data?.graduate.jobs ?? [];

  const income = useMemo(() => {
    if (jobs.length === 1) {
      return `${jobs[0].incomeCurrency} ${jobs[0].incomeAmount}`;
    }

    return '-';
  }, [jobs]);

  const employer = useMemo(() => {
    if (jobs.length === 1) {
      return jobs[0].companyName;
    }

    return !jobs.length ? '-' : 'Multiempleo';
  }, [jobs]);

  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <ContractDetailHeaderWrapper>
      <CircleButton icon="arrowLeft" variant="transparent" onClick={goBack} />
      <ContractDetailContent>
        <ContractDetailTitle>{fullName}</ContractDetailTitle>
        <ContractDetailHighlights>
          <div>
            <ContractDetailHighlightTitle>Ingresos declarados:</ContractDetailHighlightTitle>
            <ContractDetailHighlightDescription>{income}</ContractDetailHighlightDescription>
          </div>
          <div>
            <ContractDetailHighlightTitle>Empleador:</ContractDetailHighlightTitle>
            <ContractDetailHighlightDescription>{employer}</ContractDetailHighlightDescription>
          </div>
        </ContractDetailHighlights>
      </ContractDetailContent>
    </ContractDetailHeaderWrapper>
  );
};

export default ContractDetailHeader;
