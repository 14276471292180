import styled, { css } from 'styled-components';

export const TabList = styled.ul`
  display: flex;
  margin: ${({ theme }) => `${theme.spacing.xxlarge} 0`};
  background-color: ${({ theme }) => theme.colors.white};
`;

export const TabItem = styled.li`
  display: flex;
  flex: 1;
  max-width: 216px;
`;

export const TabLabel = styled.button<{ active: boolean; disabled?: boolean }>`
  appearance: none;
  line-height: 44px;
  width: 100%;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  background: white;
  border: 0;
  border-bottom: 4px solid ${({ theme }) => theme.colors.white};
  transition: border-color 800ms ease, border-color 400ms ease;

  ${({ disabled = false, theme }) =>
    !disabled &&
    css`
      &:hover {
        cursor: pointer;
        border-bottom-color: ${theme.colors.violet.light};
      }
    `};

  ${({ active, theme }) =>
    active &&
    css`
      color: ${theme.colors.violet.main};
      border-bottom-color: ${theme.colors.violet.main};
      font-weight: ${theme.fontWeights.heavy};

      &:hover {
        border-bottom-color: ${theme.colors.violet.main};
      }
    `};
`;
