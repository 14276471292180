import styled from 'styled-components';

export const ContractDetailHeaderWrapper = styled.div`
  padding: ${({ theme }) =>
    `${theme.spacing.big} 0 ${theme.spacing.xxlarge} ${theme.spacing.xxxlarge}`};
  background-color: ${({ theme }) => theme.colors.yellow.light};
  display: flex;
  column-gap: ${({ theme }) => theme.spacing.xlarge};
  align-items: baseline;
`;

export const ContractDetailContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing.xlarge};
`;

export const ContractDetailTitle = styled.h1`
  font-weight: ${({ theme }) => theme.fontWeights.black};
  font-size: ${({ theme }) => theme.fontSizes.title};
  line-height: 44px;
`;

export const ContractDetailHighlights = styled.div`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing.big};
`;

export const ContractDetailHighlightTitle = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeights.book};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  line-height: 22px;
`;

export const ContractDetailHighlightDescription = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.heavy};
  font-size: ${({ theme }) => theme.fontSizes.xlarge};
  line-height: 32px;
`;
