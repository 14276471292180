import axios, { AxiosError } from 'axios';

import { useAuth } from '../../providers/auth';
import useFetch from '../useFetch';
import api from './api';

const usePostContractsCSV = () => {
  const { getAccessToken } = useAuth();
  return useFetch(async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    const accessToken = await getAccessToken();
    const res = await api.post('/contract/csv', formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  });
};

export default usePostContractsCSV;

export function isWrongCSVException(err: any): err is AxiosError<string> {
  return (
    axios.isAxiosError(err) && err.response?.status === 400 && typeof err.response.data === 'string'
  );
}
