import React from 'react';

import { SvgIcon } from './svgIcon.styled';

const DeclarationsIcon = () => {
  return (
    <SvgIcon focusable="false" viewBox="0 0 30 30" aria-hidden="true">
      <path
        d="M7.5 2.5C6.125 2.5 5.0125 3.625 5.0125 5L5 25C5 26.375 6.1125 27.5 7.4875 27.5H22.5C23.875 27.5 25 26.375 25 25V11.0375C25 10.375 24.7375 9.7375 24.2625 9.275L18.225 3.2375C17.7625 2.7625 17.125 2.5 16.4625 2.5H7.5ZM16.25 10V4.375L23.125 11.25H17.5C16.8125 11.25 16.25 10.6875 16.25 10Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default DeclarationsIcon;
