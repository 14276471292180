import React, { useCallback, useMemo, useState } from 'react';

import { iconsMap } from '../icon';
import {
  Backdrop,
  SelectLabel,
  SelectLabelText,
  SelectOption,
  SelectOptionsWrapper,
  SelectWrapper,
} from './select-input.styled';

export interface SelectShape {
  id: string;
  label?: string;
  options: {
    label: string;
    value: string | number;
  }[];
}

export interface SelectInputProps extends SelectShape {
  value: string | number;
  onChange: (id: string, value: string) => void;
  invertPositionY?: boolean;
}

const SelectInput = ({
  label,
  onChange,
  value,
  options,
  id,
  invertPositionY = false,
}: SelectInputProps) => {
  const [showOptions, setShowOptions] = useState(false);
  const handleOpen = useCallback(() => setShowOptions(true), []);
  const handleClose = useCallback(() => setShowOptions(false), []);

  const handleChange = useCallback(
    (value: string) => {
      onChange(id, value);
      handleClose();
    },
    [id, onChange],
  );

  const opts = useMemo(
    () =>
      options.map((opt) => (
        <SelectOption key={opt.value} onClick={() => handleChange(String(opt.value))}>
          {opt.label}
        </SelectOption>
      )),
    [id, options],
  );

  return (
    <div>
      {label && <SelectLabelText>{label}</SelectLabelText>}
      <SelectWrapper>
        <SelectLabel role="switch" onClick={handleOpen} isPlaceholder={!value}>
          {value || label}
          {iconsMap.arrowDown}
        </SelectLabel>
        <Backdrop isOpen={showOptions} onClick={handleClose} />
        <SelectOptionsWrapper
          role="combobox"
          isOpen={showOptions}
          invertPositionY={invertPositionY}
        >
          {opts}
        </SelectOptionsWrapper>
      </SelectWrapper>
    </div>
  );
};

export default SelectInput;
