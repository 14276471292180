import { createContext, useCallback, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Contract, Graduate } from '../../../services/api/types';
import useEditContractById from '../../../services/api/useEditContractById';
import useEditGraduateById from '../../../services/api/useEditGraduateById';
import useGetContractById from '../../../services/api/useGetContractById';
import useGetGraduateDeclarations from '../../../services/api/useGetGraduateDeclarations';
import { ContractDetailContext, ContractDetailProviderProps } from './types';

const Context = createContext<ContractDetailContext | undefined>(undefined);

export const ContractDetailProvider = ({ children }: ContractDetailProviderProps) => {
  const [editContractById, editContractState] = useEditContractById();
  const [getContractById, contractState] = useGetContractById();
  const [editGraduateById, editGraduateState] = useEditGraduateById();
  const [getDeclarations, graduateDeclarationsState] = useGetGraduateDeclarations();
  const { id } = useParams();

  useEffect(() => {
    getContract();
  }, []);

  const getContract = useCallback(async () => {
    await getContractById(Number(id));
  }, [id]);

  const editContract = useCallback(
    async (values: Partial<Contract>) => {
      await editContractById(String(id), values).then(async () => {
        await getContract();
      });
    },
    [id],
  );

  const editGraduate = useCallback(
    async (values: Partial<Graduate>) => {
      await editGraduateById(String(values.id), values).then(async () => {
        await getContract();
      });
    },
    [id],
  );

  const getGraduateDeclarations: ContractDetailContext['getGraduateDeclarations'] = useCallback(
    async (pagination) => {
      await getDeclarations(
        Number(contractState.data?.graduate.id),
        pagination?.page ?? graduateDeclarationsState.data?.page,
      );
    },
    [contractState.data, graduateDeclarationsState.data],
  );

  return (
    <Context.Provider
      value={{
        getContract,
        contractState,
        editContract,
        editContractState,
        editGraduate,
        editGraduateState,
        getGraduateDeclarations,
        graduateDeclarationsState,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useContractDetail = (): ContractDetailContext => {
  const ctx = useContext(Context);

  if (!ctx) {
    throw new Error('Missing ContractDetailProvider');
  }

  return ctx;
};
