import React from 'react';

import { SvgIcon } from './svgIcon.styled';

const SearchIcon = () => (
  <SvgIcon focusable="false" viewBox="0 0 15 15" aria-hidden="true">
    <path
      d="M14.3779 13.3721L11.75 10.7654C12.7701 9.49353 13.2641 7.87916 13.1304 6.25425C12.9967 4.62933 12.2456 3.11738 11.0314 2.02928C9.81721 0.941185 8.23227 0.359646 6.60248 0.404243C4.97269 0.44884 3.42192 1.11618 2.26905 2.26905C1.11618 3.42192 0.44884 4.97269 0.404243 6.60248C0.359646 8.23227 0.941185 9.81721 2.02928 11.0314C3.11738 12.2456 4.62933 12.9967 6.25425 13.1304C7.87916 13.2641 9.49353 12.7701 10.7654 11.75L13.3721 14.3567C13.4379 14.4231 13.5163 14.4757 13.6026 14.5117C13.6889 14.5477 13.7815 14.5662 13.875 14.5662C13.9685 14.5662 14.0611 14.5477 14.1474 14.5117C14.2337 14.4757 14.3121 14.4231 14.3779 14.3567C14.5056 14.2246 14.5769 14.0481 14.5769 13.8644C14.5769 13.6807 14.5056 13.5042 14.3779 13.3721ZM6.79166 11.75C5.81099 11.75 4.85235 11.4592 4.03696 10.9144C3.22156 10.3695 2.58604 9.59515 2.21076 8.68913C1.83547 7.78312 1.73728 6.78616 1.9286 5.82434C2.11992 4.86252 2.59215 3.97902 3.28559 3.28559C3.97902 2.59215 4.86252 2.11992 5.82434 1.9286C6.78616 1.73728 7.78312 1.83547 8.68913 2.21076C9.59515 2.58604 10.3695 3.22156 10.9144 4.03696C11.4592 4.85235 11.75 5.81099 11.75 6.79166C11.75 8.10669 11.2276 9.36786 10.2977 10.2977C9.36786 11.2276 8.10669 11.75 6.79166 11.75Z"
      fill="#464646"
    />
  </SvgIcon>
);

export default SearchIcon;
