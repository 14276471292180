import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const fontRepository = 'https://henry-social-resources.s3-sa-east-1.amazonaws.com/fonts/Avenir';
const unicodeRange =
  'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF';
const fontProps = `font-style: normal; font-display: swap; unicode-range: ${unicodeRange}`;

const FontsAndGlobalStyles = createGlobalStyle`
  @font-face {
    font-family: "Avenir";
    src: url('${fontRepository}/Avenir-Black-03-968e784956ff9b6a05c29cc3be18cad0.ttf') format('truetype');
    font-weight: 900;
    ${fontProps}
  }
  @font-face {
    font-family: "Avenir";
    src: url('${fontRepository}/Avenir-Heavy-05-ff80b6894f8375c59a197caa24aac91c.ttf') format('truetype');
    font-weight: 700;
    ${fontProps}
  }
  @font-face {
    font-family: "Avenir";
    src: url('${fontRepository}/Avenir-Book-01-995b34ab56b423abffe48e64bf8b8df1.ttf') format('truetype');
    font-weight: 300;
    ${fontProps}
  }
  @font-face {
    font-family: "Avenir";
    src: url('${fontRepository}/Avenir-Light-07-33f6570a328d84cb7d06e829a0c7ca1b.ttf') format('truetype');
    font-weight: 100;
    ${fontProps}
  }
  @font-face {
    font-family: "Avenir";
    src: url('${fontRepository}/Avenir-Medium-09-7d635ea94b4460e86cd678e9e754a643.ttf') format('truetype');
    font-weight: 500;
    ${fontProps}
  }
  @font-face {
    font-family: "Avenir";
    src: url('${fontRepository}/Avenir-Roman-12-c6d37b26297461a78d10f169dd5cf46d.ttf') format('truetype');
    font-weight: 400;
    ${fontProps}
  }

  ${reset}

  body {
    font-family: "Avenir", sans-serif;
    font-weight: 400;
    overflow: hidden;
  }
  * {
    font-family: "Avenir", sans-serif;
    scrollbar-color: #d5d5d5 transparent;
    scrollbar-width: thin;
  }
  *::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  *::-webkit-scrollbar:vertical {
    width: '10px';
  }
  *::-webkit-scrollbar-button,
  *::-webkit-scrollbar-button:increment {
    display: none;
  }
  *::-webkit-scrollbar:horizontal {
    height: 10px;
  }
  *::-webkit-scrollbar-thumb {
    cursor: move;
    background-color: #d5d5d5;
    border-radius: 20px;
    border: none;
  }
  *::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }
`;

export default FontsAndGlobalStyles;
