import React from 'react';

import ContractDetailPage from './contract-detail';
import { ContractDetailProvider } from './provider/context';

const ContractDetailContainer = () => (
  <ContractDetailProvider>
    <ContractDetailPage />
  </ContractDetailProvider>
);

export default ContractDetailContainer;
