import React from 'react';

import { ContentWrapper } from '../../components/layout/layout.styled';
import { PageTitle } from '../../components/page-title/page-title.styled';
import ContractsCSVChargeSideBar from './components/contracts-csv-upload-sidebar/contracts-csv-upload-sidebar';
import ContractsData from './components/contracts-data/contracts-data';

import { ContractsContainer, HeaderAndAction } from './contracts.styled';

const ContractsPage = () => (
  <ContentWrapper>
    <ContractsContainer>
      <HeaderAndAction>
        <PageTitle>Contratos</PageTitle>
        <ContractsCSVChargeSideBar />
      </HeaderAndAction>
      <ContractsData />
    </ContractsContainer>
  </ContentWrapper>
);

export default ContractsPage;
