import styled from 'styled-components';

export const Title = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes.xlarge};
  line-height: 33px;
  color: ${({ theme }) => theme.colors.black};
`;

export const Text = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  line-height: 24px;
`;

export const Content = styled.div`
  width: 100%;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: ${({ theme }) => theme.spacing.small};
`;

export const Expander = styled.div`
  width: 100%;
  flex-grow: 1;
`;
