import React, { useCallback, useEffect, useMemo } from 'react';

import Pagination from '../../../../components/pagination/pagination';

import useQuery from '../../../../hooks/useQuery';
import {
  ContractActivationReason,
  ContractSociety,
  ContractSort,
  ContractState,
  ContractVersion,
  SortDirection,
} from '../../../../services/api/types';
import useGetContracts from '../../../../services/api/useGetContracts';
import { ContractsDataContainer } from '../../contracts.styled';
import ContractsFilters from '../contracts-filters/contracts-filters';
import ContractsTable from '../contracts-table/contracts-table';

const resetPagination = { page: '0' };

const ContractsData = () => {
  const { query, changeQuery, resetQuery, getQuery, getArrayQuery } = useQuery();
  const [getContracts, { data }] = useGetContracts();

  const filters = useMemo(
    () => ({
      search: getQuery('search', ''),
      activationReason: getArrayQuery<ContractActivationReason>('activationReason', []),
      society: getArrayQuery<ContractSociety>('society', []),
      version: getArrayQuery<ContractVersion>('version', []),
      state: getArrayQuery<ContractState>('state', []),
    }),
    [query.search, query.activationReason, query.society, query.version, query.state],
  );

  const sort = useMemo(
    () => ({
      sortBy: getQuery<ContractSort>('sortBy', 'graduateFullName'),
      sortDirection: getQuery<SortDirection>('sortDirection', 'ASC'),
    }),
    [query.sortBy, query.sortDirection],
  );

  const pagination = useMemo(
    () => ({
      page: Number(getQuery('page')) || 0,
      pageSize: Number(getQuery('pageSize')) || 5,
    }),
    [query.page, query.pageSize],
  );

  const handleFilterChange = useCallback(
    (id: string, value: unknown) => {
      changeQuery({ [id]: String(value), ...resetPagination });
    },
    [changeQuery],
  );

  const handleFilterReset = useCallback(() => {
    resetQuery(['pageSize', 'sortBy', 'sortDirection']);
  }, [changeQuery]);

  const handleSortChange = useCallback(
    ({ sortBy, sortDirection }: { sortBy: string; sortDirection: SortDirection }) => {
      changeQuery({ sortBy, sortDirection, ...resetPagination });
    },
    [changeQuery],
  );

  const handlePaginationChange = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) => {
      changeQuery({ page: String(page), pageSize: String(pageSize) });
    },
    [changeQuery],
  );

  useEffect(() => {
    getContracts({ ...filters, ...sort, ...pagination });
  }, [filters, sort, pagination]);

  return (
    <>
      <ContractsFilters
        filters={filters}
        sort={sort}
        onFilterChange={handleFilterChange}
        onFilterReset={handleFilterReset}
      />
      <ContractsDataContainer>
        <ContractsTable
          data={data?.data}
          sortBy={sort.sortBy}
          sortDirection={sort.sortDirection}
          onSortChange={handleSortChange}
        />
        <Pagination
          page={pagination.page}
          pageSize={pagination.pageSize}
          totalCount={data?.totalCount}
          onChange={handlePaginationChange}
          showPageSize
        />
      </ContractsDataContainer>
    </>
  );
};

export default ContractsData;
