import { useAuth } from '../../providers/auth';
import useFetch from '../useFetch';
import api from './api';

const useGetContractsCSVTemplate = () => {
  const { getAccessToken } = useAuth();
  return useFetch(async () => {
    const accessToken = await getAccessToken();
    const res = await api.get<string>('/contract/csv/template', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  });
};

export default useGetContractsCSVTemplate;
