import styled from 'styled-components';

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.main};
  padding: 0 ${({ theme }) => theme.spacing.xxlarge};
  height: ${({ theme }) => theme.spacing.headerHolder};
`;

export const HeaderAppName = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xxsmall};
`;

export const AppName = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.large};
  border-left: 3px solid ${({ theme }) => theme.colors.gray.placeholder};
  padding-left: ${({ theme }) => theme.spacing.xlarge};
  margin-left: ${({ theme }) => theme.spacing.xlarge};
`;
