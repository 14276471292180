import { useAuth } from '../../providers/auth';
import useFetch from '../useFetch';
import api from './api';
import { Graduate } from './types';

export interface EditGraduateByIdResponse {
  message: string | string[];
  statusCode?: number;
  error?: string;
}

const useEditGraduateById = () => {
  const { getAccessToken } = useAuth();

  return useFetch(async (id: string, data: Partial<Graduate>) => {
    const accessToken = await getAccessToken();

    const res = await api.patch<EditGraduateByIdResponse>(`/graduate/${id}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  });
};

export default useEditGraduateById;
