import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function useQuery() {
  const location = useLocation();
  const navigate = useNavigate();

  const query = useMemo(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const query: Record<string, string | undefined> = {};
    urlSearchParams.forEach((value, name) => {
      query[name] = value;
    });
    return query;
  }, [location.search]);

  const changeQuery = useCallback(
    (queryChanges: Record<string, string | undefined>) => {
      const newUrl = new URL(window.location.href);
      for (const key in query) {
        const value = query[key];
        if (value) {
          newUrl.searchParams.set(key, value);
        }
      }
      Object.entries(queryChanges).forEach(([name, value]) => {
        if (value && value !== 'undefined') {
          newUrl.searchParams.set(name, value);
        } else {
          newUrl.searchParams.delete(name);
        }
      });
      navigate(`${newUrl.pathname}${newUrl.search}`);
    },
    [query, navigate],
  );

  const resetQuery = useCallback(
    (paramsToKeep: string[]) => {
      const newUrl = new URL(window.location.href);
      newUrl.search = '';

      for (const param in paramsToKeep) {
        const name = paramsToKeep[param];
        const value = query[name];

        if (value) {
          newUrl.searchParams.set(name, value);
        }
      }

      navigate(`${newUrl.pathname}${newUrl.search}`);
    },
    [query, navigate],
  );

  const getQuery = useCallback(
    <T extends string = string>(key: string, defaulValue?: T) => {
      return (query[key] || defaulValue) as T;
    },
    [query],
  );

  const getArrayQuery = useCallback(
    <T extends string = string>(key: string, defaulValue?: T[]) => {
      return (query[key]?.split(',') || defaulValue) as T[];
    },
    [query],
  );

  return { query, getQuery, getArrayQuery, changeQuery, resetQuery };
}
