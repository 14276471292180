import React from 'react';

import { ContentWrapper, LowerContentWrapper } from '../../components/layout/layout.styled';
import Tabs from '../../components/tabs/tabs';
import ContractDetailContentDeclarations from './components/contract-detail-content/contract-detail-content-declarations';
import ContractDetailContentProfile from './components/contract-detail-content/contract-detail-content-profile';
import ContractDetailHeader from './components/contract-detail-header/contract-detail-header';

const ContractDetailPage = () => (
  <ContentWrapper>
    <ContractDetailHeader />
    <LowerContentWrapper>
      <Tabs
        initialTab="Perfil"
        tabs={[
          {
            label: 'Perfil',
            content: <ContractDetailContentProfile />,
          },
          {
            label: 'Declaraciones',
            content: <ContractDetailContentDeclarations />,
          },
        ]}
      />
    </LowerContentWrapper>
  </ContentWrapper>
);

export default ContractDetailPage;
