import cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import removeCookies from '../../utils/removeCookies';
import { AUTH } from './constants';
import {
  AuthContext,
  AuthProviderProps,
  AuthState,
  DecodedAccessToken,
  DecodedIdToken,
} from './types';

const Context = createContext<AuthContext | undefined>(undefined);

export const INITIAL_STATE: AuthState = {
  isLoading: true,
  isAuthenticated: false,
  accessTokenExpiration: null,
  accessToken: null,
  idToken: null,
  user: null,
};

export const AuthProvider = ({ children, redirectUri }: AuthProviderProps) => {
  const [state, setState] = useState<AuthState>(INITIAL_STATE);

  useEffect(() => {
    const cookiesCredentials = {
      accessToken: cookies.get('accessToken'),
      idToken: cookies.get('idToken'),
    };

    const decodedAccessToken = cookiesCredentials.accessToken
      ? jwtDecode<DecodedAccessToken>(cookiesCredentials.accessToken)
      : null;
    const decodedIdToken = cookiesCredentials.idToken
      ? jwtDecode<DecodedIdToken>(cookiesCredentials.idToken)
      : null;

    const accessTokenExpiration =
      decodedAccessToken && new Date(decodedAccessToken.exp * AUTH.EXPIRATION_MULTIPLIER);

    if (
      !cookiesCredentials.accessToken ||
      !cookiesCredentials.idToken ||
      !accessTokenExpiration ||
      new Date() > accessTokenExpiration
    ) {
      setState({ ...INITIAL_STATE, isLoading: false });
    } else {
      setState({
        isLoading: false,
        isAuthenticated: true,
        accessTokenExpiration,
        accessToken: cookiesCredentials.accessToken,
        idToken: cookiesCredentials.idToken,
        user: decodedIdToken,
      });
    }
  }, []);

  const redirectTologin: AuthContext['redirectTologin'] = useCallback(
    (options?: { redirectUri?: string }) => {
      const loginUrl = new URL('/', AUTH.URL);

      const redirect = options?.redirectUri || redirectUri || window.location.href;
      loginUrl.searchParams.append(AUTH.REDIRECT_PARAM, redirect);
      window.location.replace(loginUrl.toString());
    },
    [redirectUri],
  );

  const doLogout: AuthContext['doLogout'] = useCallback(() => {
    setState({ ...INITIAL_STATE, isLoading: false });
    removeCookies();
  }, [setState]);

  const getAccessToken: AuthContext['getAccessToken'] = useCallback(() => {
    const { accessToken, accessTokenExpiration } = state;
    let token = null;

    if (accessToken && accessTokenExpiration && new Date() < accessTokenExpiration) {
      token = accessToken;
    }

    return Promise.resolve(token);
  }, [state]);

  return (
    <Context.Provider
      value={{
        isLoading: state.isLoading,
        isAuthenticated: state.isAuthenticated,
        user: state.user,
        getAccessToken,
        redirectTologin,
        doLogout,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useAuth = (): AuthContext => {
  const ctx = useContext(Context);

  if (!ctx) {
    throw new Error('Missing AuthProvider');
  }

  return ctx;
};
