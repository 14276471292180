import { useMemo, useRef } from 'react';

const useDebounce = <Args extends any[] = unknown[]>(fn: (...args: Args) => void, time = 500) => {
  const timeout = useRef<NodeJS.Timeout>(null);

  const debouncedFn = useMemo(() => {
    return (...args: Args) => {
      if (timeout.current) clearTimeout(timeout.current);
      const newTimeout = setTimeout(() => {
        fn(...args);
        Object.assign(timeout, { current: null });
      }, time);
      Object.assign(timeout, { current: newTimeout });
    };
  }, [fn, time]);

  return debouncedFn;
};

export default useDebounce;
