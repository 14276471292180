import { Filter } from '../../../../components/filters/types';

const filters: Filter[] = [
  { type: 'input', id: 'search', placeholder: 'Buscar por nombre o Email' },
  {
    type: 'multiselect',
    id: 'society',
    label: 'Sociedad',
    optionsTitle: 'Sociedades',
    options: [
      { value: 'Henry Tech', label: 'Henry Tech' },
      { value: 'SAS', label: 'SAS' },
    ],
  },
  {
    type: 'multiselect',
    id: 'activationReason',
    label: 'Motivo',
    optionsTitle: 'Motivos',
    options: [
      { value: 'graduation', label: 'Graduado' },
      { value: 'withdraw', label: 'Baja' },
    ],
  },
  {
    type: 'multiselect',
    id: 'version',
    label: 'Contrato',
    optionsTitle: 'Contratos',
    options: [
      { value: 'v1.0', label: 'v1.0' },
      { value: 'v2.0', label: 'v2.0' },
      { value: 'v2.2', label: 'v2.2' },
      { value: 'v3.0', label: 'v3.0' },
      { value: 'v3.1', label: 'v3.1' },
    ],
  },
  {
    type: 'multiselect',
    id: 'state',
    label: 'Estado',
    optionsTitle: 'Estados',
    options: [
      { value: 'inactive', label: 'Inactivo' },
      { value: 'active', label: 'Activo' },
      { value: 'finished', label: 'Finalizado' },
    ],
  },
];

export default filters;
