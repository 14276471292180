import styled from 'styled-components';

export const ContractsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ theme }) => `${theme.spacing.big} ${theme.spacing.large} 0`};
  transition: 0.5s padding ease-in-out;

  @media (min-width: 900px) {
    padding-left: ${({ theme }) => theme.spacing.large};
  }

  @media (min-width: 980px) {
    padding-left: ${({ theme }) => theme.spacing.xxxlarge};
    padding-right: ${({ theme }) => theme.spacing.xxxlarge};
  }

  @media (min-width: 1100px) {
    padding-left: ${({ theme }) => theme.spacing.gigant};
    padding-right: ${({ theme }) => theme.spacing.gigant};
  }
`;

export const ContractsDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const HeaderAndAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
