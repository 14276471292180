import React, { useCallback, useMemo } from 'react';

import CircleButton from '../button/circle/circle';
import SelectInput from '../select-input/select-input';
import { PaginationIndicator, PaginationWrapper } from './pagination.styled';

interface Props {
  page: number;
  pageSize: number;
  totalCount?: number;
  showPageSize?: boolean;
  onChange: (pagination: { page: number; pageSize: number }) => void;
}

const pageSizeOptions = Array.from({ length: 10 }, (_e, i: number) => {
  const size = (i + 1) * 5;

  return {
    label: `${size} filas`,
    value: size,
  };
});

const Pagination = ({ page, pageSize, totalCount = 0, onChange, showPageSize = false }: Props) => {
  const totalPages = useMemo(() => Math.ceil(totalCount / pageSize), [totalCount, pageSize]);
  const currentPage = useMemo(() => page + 1, [page]);
  const isPreviousPageDisabled = useMemo(() => page === 0, [page]);
  const isNextPageDisabled = useMemo(
    () => currentPage === totalPages || totalPages === 0,
    [currentPage, totalPages],
  );

  const handlePreviousPage = useCallback(
    () => onChange({ pageSize, page: page - 1 }),
    [onChange, page, pageSize],
  );

  const handleNextPage = useCallback(
    () => onChange({ pageSize, page: page + 1 }),
    [onChange, page, pageSize],
  );

  const handlePageSizeChange = useCallback(
    (_id: string, pageSize: string) => onChange({ pageSize: Number(pageSize), page: 0 }),
    [onChange, page, pageSize],
  );

  return (
    <PaginationWrapper role="navigation">
      <CircleButton
        onClick={handlePreviousPage}
        disabled={isPreviousPageDisabled}
        icon="prev"
        size="small"
      />
      <PaginationIndicator>
        <span>{currentPage}</span> de {totalPages}
      </PaginationIndicator>
      <CircleButton
        onClick={handleNextPage}
        disabled={isNextPageDisabled}
        icon="next"
        size="small"
      />
      {showPageSize && (
        <SelectInput
          id="pageSize"
          options={pageSizeOptions}
          onChange={handlePageSizeChange}
          value={pageSize}
          invertPositionY
        />
      )}
    </PaginationWrapper>
  );
};

export default Pagination;
