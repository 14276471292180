import styled, { css } from 'styled-components';

export const MultiselectWrapper = styled.div`
  position: relative;
`;

export const MultiselectLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 70px;
  cursor: pointer;
  padding: 0 ${({ theme }) => theme.spacing.normal};
  font-size: ${({ theme }) => theme.fontSizes.normal};
  line-height: 28px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray.medium};
  border-radius: ${({ theme }) => theme.radius.small};
`;

export const Backdrop = styled.div<{ isOpen?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  pointer-events: none;
  opacity: 0;
  ${({ isOpen }) =>
    isOpen &&
    css`
      pointer-events: all;
    `}
`;

export const MultiselectOptionsWrapper = styled.div<{ isOpen?: boolean }>`
  display: flex;
  position: absolute;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  width: 110px;
  margin-top: ${({ theme }) => theme.spacing.xxxsmall};
  padding: ${({ theme }) =>
    `${theme.spacing.xsmall} ${theme.spacing.small} ${theme.spacing.xxsmall}`};
  row-gap: ${({ theme }) => theme.spacing.xxsmall};
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray.medium};
  border-radius: ${({ theme }) => theme.radius.small};
  transition: 0.3s opacity;
  z-index: 100;

  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

export const MultiselectOptionsTitle = styled.span`
  line-height: 1rem;
  font-size: ${({ theme }) => theme.fontSizes.normal};
`;

export const MultiselectOption = styled.label`
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.regular};
  padding: ${({ theme }) => theme.spacing.xxsmall} ${({ theme }) => theme.spacing.xxxsmall};
  column-gap: ${({ theme }) => theme.spacing.normal};
  align-items: end;
`;

export const MultiselectCheckbox = styled.input`
  margin: ${({ theme }) => theme.spacing.none};
  width: 16px;
  height: 16px;
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: ${({ theme }) => theme.radius.xsmall};

  &:checked {
    accent-color: ${({ theme }) => theme.colors.violet.dark};
  }
`;
