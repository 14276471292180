import React, { ChangeEvent, useCallback, useState } from 'react';

import { iconsMap } from '../icon';
import {
  Backdrop,
  MultiselectCheckbox,
  MultiselectLabel,
  MultiselectOption,
  MultiselectOptionsTitle,
  MultiselectOptionsWrapper,
  MultiselectWrapper,
} from './multiselect-input.styled';

export interface MultiSelectShape {
  id: string;
  label: string;
  optionsTitle?: string;
  options: {
    label: string;
    value: string;
  }[];
}

export interface MultiSelectInputProps extends MultiSelectShape {
  value: string[];
  onChange: (id: string, value: string[]) => void;
}

const MultiSelectInput = ({
  label,
  onChange,
  value,
  options,
  optionsTitle,
  id,
}: MultiSelectInputProps) => {
  const [showOptions, setShowOptions] = useState(false);
  const handleOpen = useCallback(() => setShowOptions(true), []);
  const handleClose = useCallback(() => setShowOptions(false), []);

  const handleClick = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const isChecked = e.target.checked;

      if (isChecked) {
        onChange(id, [...value, e.target.value]);
      } else {
        onChange(
          id,
          value.filter((val) => val !== e.target.value),
        );
      }
    },
    [value],
  );

  return (
    <MultiselectWrapper>
      <MultiselectLabel role="switch" onClick={handleOpen}>
        {label}
        {iconsMap.arrowDown}
      </MultiselectLabel>
      <Backdrop isOpen={showOptions} onClick={handleClose} role="presentation" />
      <MultiselectOptionsWrapper role="combobox" isOpen={showOptions}>
        {optionsTitle && <MultiselectOptionsTitle>{optionsTitle}</MultiselectOptionsTitle>}
        {options?.map((opt) => (
          <MultiselectOption key={opt.value} htmlFor={opt.value}>
            <MultiselectCheckbox
              id={opt.value}
              type="checkbox"
              role="checkbox"
              value={opt.value}
              onChange={handleClick}
              checked={value.includes(opt.value)}
            />
            {opt.label}
          </MultiselectOption>
        ))}
      </MultiselectOptionsWrapper>
    </MultiselectWrapper>
  );
};

export default MultiSelectInput;
