import React, { ReactNode } from 'react';

import { ButtonColor, ButtonSize, ButtonSpacing, ButtonType, ButtonVariant } from '../types';
import { ButtonStyled } from './regular.styled';

interface ButtonProps {
  onClick?: () => void;
  variant?: ButtonVariant;
  color?: ButtonColor;
  size?: ButtonSize;
  spacing?: ButtonSpacing;
  disabled?: boolean;
  className?: string;
  children?: ReactNode;
  type?: ButtonType;
}

const Button = ({
  onClick,
  disabled = false,
  size = 'regular',
  variant = 'transparent',
  color = 'default',
  spacing = 'none',
  type = 'button',
  className,
  children,
}: ButtonProps) => {
  return (
    <ButtonStyled
      onClick={onClick}
      variant={variant}
      color={color}
      size={size}
      type={type}
      spacing={spacing}
      disabled={disabled}
      className={className}
    >
      {children}
    </ButtonStyled>
  );
};

export default Button;
