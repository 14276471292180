import styled, { css } from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputLabel = styled.label`
  color: ${({ theme }) => theme.colors.gray.placeholder};
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

export const InputWrapper = styled.div<{ hasError: boolean; hasLabel: boolean }>`
  display: flex;
  flex: 1;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing.small};
  padding: 0 ${({ theme }) => theme.spacing.xxsmall} 0 ${({ theme }) => theme.spacing.normal};
  font-size: ${({ theme }) => theme.fontSizes.regular};
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray.medium};
  border-radius: ${({ theme }) => theme.radius.small};

  ${({ hasLabel = false, theme }) =>
    hasLabel &&
    css`
      margin-top: ${theme.spacing.xxxsmall};
    `};

  ${({ hasError = false, theme }) =>
    hasError &&
    css`
      border-color: ${theme.colors.red.light};
      margin-bottom: ${theme.spacing.xsmall};
    `}
`;

export const Input = styled.input`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  appearance: none;
  padding: 0;
  border: 0;
  line-height: 46px;
  width: 100%;

  &:focus-visible {
    outline: 0;
  }
`;

export const InputError = styled.span`
  color: ${({ theme }) => theme.colors.red.light};
  font-size: ${({ theme }) => theme.fontSizes.normal};
`;
