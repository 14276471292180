import { useAuth } from '../../providers/auth';
import useFetch from '../useFetch';
import api from './api';
import { Contract } from './types';

export interface EditContractByIdResponse {
  message: string | string[];
  statusCode?: number;
  error?: string;
}

const useEditContractById = () => {
  const { getAccessToken } = useAuth();

  return useFetch(async (id: string, data: Partial<Contract>) => {
    const accessToken = await getAccessToken();

    const res = await api.patch<EditContractByIdResponse>(`/contract/${id}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  });
};

export default useEditContractById;
