import React from 'react';

import { Cell } from './table.styled';
import { Columns, Data } from './types';

export interface TableBodyProps {
  columns: Columns;
  data?: Data;
}

const TableBody = ({ columns, data }: TableBodyProps) => (
  <tbody>
    {data?.map((row) => (
      <tr key={row.id}>
        {columns?.map((col) => (
          <Cell key={`row-${col.id}`}>
            {col.format ? col.format(row.id, row[col.id]) : row[col.id]}
          </Cell>
        ))}
      </tr>
    ))}
  </tbody>
);

export default React.memo(TableBody);
