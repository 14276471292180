import styled from 'styled-components';

import CircleButton from '../button/circle/circle';

export const ChipWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.radius.chip};
  background-color: ${({ theme }) => theme.colors.violet.light};
  padding: ${({ theme }) => theme.spacing.xxsmall} ${({ theme }) => theme.spacing.normal};
  padding-right: 2rem;
  font-size: ${({ theme }) => theme.fontSizes.normal};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 1rem;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.violet.dark};
  position: relative;
`;

export const ClearButton = styled(CircleButton)`
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translate(50%, -50%);
`;

export const ChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.xxsmall};
  margin: ${({ theme }) => `${theme.spacing.normal} 0`};
`;
