import React, { useCallback } from 'react';

import { ChipsContainer as FiltersApplied } from '../../../../components/chip/chip.styled';
import Filters from '../../../../components/filters/filters';

import { ContractSort, SortDirection } from '../../../../services/api/types';
import { formatContractActivationReason, formatContractState } from '../../utils';
import AppliedFiltersGroup from './applied-filters-group';
import ContractsFiltersDownload from './contracts-filters-download';
import { FiltersContainer } from './contracts-filters.styled';
import filtersConfig from './filtersConfig';

export interface ContractsFiltersProps {
  filters: Record<string, string | string[]>;
  onFilterChange: (id: string, value: string | string[]) => void;
  onFilterReset: () => void;
  sort: {
    sortBy: ContractSort;
    sortDirection: SortDirection;
  };
}

const ContractsFilters = ({
  filters,
  onFilterChange,
  onFilterReset,
  sort,
}: ContractsFiltersProps) => {
  const handleClear = useCallback(
    (id: string, valueToRemove: string) => {
      const prevValue = filters[id] as string | string[];
      if (typeof prevValue === 'string') {
        onFilterChange(id, '');
      } else {
        const newValue = prevValue.filter((value) => value !== valueToRemove);
        onFilterChange(id, newValue);
      }
    },
    [onFilterChange, filters],
  );

  return (
    <>
      <FiltersContainer>
        <Filters
          filters={filtersConfig}
          filtersApplied={filters}
          onChangeFilter={onFilterChange}
          onResetFilters={onFilterReset}
        />
        <ContractsFiltersDownload filtersApplied={filters} sort={sort} />
      </FiltersContainer>
      <FiltersApplied>
        <AppliedFiltersGroup id="search" value={filters.search} onClear={handleClear} />
        <AppliedFiltersGroup id="society" value={filters.society} onClear={handleClear} />
        <AppliedFiltersGroup
          id="activationReason"
          value={filters.activationReason}
          onClear={handleClear}
          format={formatContractActivationReason}
        />
        <AppliedFiltersGroup id="version" value={filters.version} onClear={handleClear} />
        <AppliedFiltersGroup
          id="state"
          value={filters.state}
          onClear={handleClear}
          format={formatContractState}
        />
      </FiltersApplied>
    </>
  );
};

export default ContractsFilters;
