import styled from 'styled-components';

export const Title = styled.h4`
  color: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.xlarge};
  line-height: 30px;
`;

export const Content = styled.div`
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const ErrorMessage = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.red.main};
  line-height: 24px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.large};
`;

export const FormFieldsVisible = styled(FormFields)<{ show: boolean }>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
`;
