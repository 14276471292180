import React from 'react';

import { SvgIcon } from './svgIcon.styled';

const UsersIcon = () => {
  return (
    <SvgIcon focusable="false" viewBox="0 0 30 30" aria-hidden="true">
      <path
        d="M20.8379 16.4121C22.5504 17.5746 23.7504 19.1496 23.7504 21.2496V24.9996H27.5004C28.1879 24.9996 28.7504 24.4371 28.7504 23.7496V21.2496C28.7504 18.5246 24.2879 16.9121 20.8379 16.4121V16.4121Z"
        fill="currentColor"
      />
      <path
        d="M11.25 15C14.0114 15 16.25 12.7614 16.25 10C16.25 7.23858 14.0114 5 11.25 5C8.48858 5 6.25 7.23858 6.25 10C6.25 12.7614 8.48858 15 11.25 15Z"
        fill="currentColor"
      />
      <path
        d="M18.75 15C21.5125 15 23.75 12.7625 23.75 10C23.75 7.2375 21.5125 5 18.75 5C18.1625 5 17.6125 5.125 17.0875 5.3C18.125 6.5875 18.75 8.225 18.75 10C18.75 11.775 18.125 13.4125 17.0875 14.7C17.6125 14.875 18.1625 15 18.75 15ZM11.25 16.25C7.9125 16.25 1.25 17.925 1.25 21.25V23.75C1.25 24.4375 1.8125 25 2.5 25H20C20.6875 25 21.25 24.4375 21.25 23.75V21.25C21.25 17.925 14.5875 16.25 11.25 16.25Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default UsersIcon;
