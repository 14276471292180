import styled, { css } from 'styled-components';

import CircleButton from '../button/circle/circle';

export const Backdrop = styled.div<{ isOpen?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: ${({ theme }) => theme.zIndex.overlay};
  background-color: rgba(0, 0, 0, 0.75);
  transition: opacity 200ms;
  pointer-events: none;
  opacity: 0;
  ${({ isOpen }) =>
    isOpen &&
    css`
      pointer-events: all;
      opacity: 1;
    `}
`;

export const DrawerContainer = styled.div<{ isOpen?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 480px;
  max-width: 100vw;
  z-index: ${({ theme }) => theme.zIndex.modal};
  box-sizing: border-box;
  padding: ${({ theme }) => `${theme.spacing.gigant} ${theme.spacing.big} ${theme.spacing.action}`};
  background-color: ${({ theme }) => theme.colors.gray.background};
  display: flex;
  flex-direction: column;
  transition: opacity 300ms, transform 225ms;
  opacity: 0;

  pointer-events: none;
  transform: translateX(100%);
  ${({ isOpen }) =>
    isOpen &&
    css`
      transition: transform 225ms, opacity 0ms;
      pointer-events: all;
      transform: translateX(0%);
      opacity: 1;
    `}
`;

export const CloseButton = styled(CircleButton)`
  position: absolute;
  top: 26px;
  right: 26px;
`;
