import styled, { css } from 'styled-components';

export const SelectWrapper = styled.div`
  position: relative;
`;

export const SelectLabelText = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.colors.gray.placeholder};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  margin-bottom: ${({ theme }) => theme.spacing.xxxsmall};
`;

export const SelectLabel = styled.div<{ isPlaceholder: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 70px;
  cursor: pointer;
  padding: 0 ${({ theme }) => theme.spacing.normal};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  line-height: 46px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray.medium};
  border-radius: ${({ theme }) => theme.radius.regular};

  ${({ isPlaceholder, theme }) =>
    isPlaceholder &&
    css`
      color: ${theme.colors.gray.medium};
    `};
`;

export const Backdrop = styled.div<{ isOpen?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  pointer-events: none;
  opacity: 0;
  ${({ isOpen }) =>
    isOpen &&
    css`
      pointer-events: all;
    `}
`;

export const SelectOptionsWrapper = styled.div<{ isOpen: boolean; invertPositionY: boolean }>`
  display: flex;
  position: absolute;
  flex-direction: column;
  opacity: 0;
  width: 100%;
  visibility: hidden;
  margin-top: ${({ theme }) => theme.spacing.xxxsmall};
  row-gap: ${({ theme }) => theme.spacing.xxsmall};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: ${({ theme }) => theme.radius.regular};
  transition: 0.3s opacity;
  z-index: 100;

  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
      visibility: visible;
    `}

  ${({ invertPositionY }) =>
    invertPositionY &&
    css`
      bottom: 40px;
    `}
`;

export const SelectOption = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  padding: ${({ theme }) => theme.spacing.normal} ${({ theme }) => theme.spacing.xlarge};
  column-gap: ${({ theme }) => theme.spacing.normal};
  cursor: pointer;
  line-height: 20px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray.lighter};
  }
`;
