import styled, { css, keyframes } from 'styled-components';

import { SortDirection } from './types';

export const TableWrapper = styled.table`
  background: ${({ theme }) => theme.colors.white};
`;

export const TableHeader = styled.thead`
  background: ${({ theme }) => theme.colors.gray.main};
`;

const commonCellStyle = css`
  padding: ${({ theme }) => theme.spacing.normal};
  border: 1px solid ${({ theme }) => theme.colors.gray.lighter};
  font-size: ${({ theme }) => theme.fontSizes.regular};
  line-height: 24px;
  text-align: left;
`;

export const ColumnHeader = styled.th<{ isSortEnabled: boolean }>`
  ${commonCellStyle}
  font-weight: ${({ theme }) => theme.fontWeights.heavy};
  position: relative;

  ${({ isSortEnabled }) => isSortEnabled && `cursor: pointer`}
`;

export const Cell = styled.td`
  ${commonCellStyle}
  font-weight: ${({ theme }) => theme.fontWeights.book};
`;

const GradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

export const CellLoader = styled(Cell)`
  padding: 26px;
  background: ${({ theme }) =>
    `linear-gradient(-45deg, ${theme.colors.gray.main}, ${theme.colors.gray.light}, ${theme.colors.gray.main}, ${theme.colors.gray.light})`};
  background-size: 400% 400%;
  animation: ${GradientAnimation} 6s ease infinite;
`;

export const SortIconContainer = styled.div<{ show: boolean; direction?: SortDirection }>`
  position: absolute;
  top: 50%;
  right: ${({ theme }) => theme.spacing.normal};
  display: inline-flex;
  transition: transform 250ms, opacity 200ms;
  transform: translateY(-50%)
    rotateZ(${({ direction }) => (direction === 'DESC' ? '180deg' : '0deg')});
  opacity: ${({ show = true }) => (show ? 1 : 0)};
`;
