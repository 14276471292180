import React from 'react';

import { SvgIcon } from './svgIcon.styled';

const FiltersIcon = () => (
  <SvgIcon focusable="false" viewBox="0 0 18 12" aria-hidden="true">
    <path d="M1 1H17" stroke="black" strokeWidth="2" strokeLinecap="round" />
    <path d="M4.19995 6H13.8" stroke="black" strokeWidth="2" strokeLinecap="round" />
    <path d="M7.40002 11H10.6" stroke="black" strokeWidth="2" strokeLinecap="round" />
  </SvgIcon>
);

export default FiltersIcon;
