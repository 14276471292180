import React, { useCallback } from 'react';

import isStringArray from '../../utils/isStringArray';
import CircleButton from '../button/circle/circle';
import FiltersIcon from '../icon/filters';
import Multiselect from '../multiselect-input/multiselect-input';
import SearchInput from '../search-input/search-input';
import {
  FiltersContainer,
  FiltersTitle,
  FiltersTitleWrapper,
  FiltersWrapper,
} from './filters.styled';
import { Filter } from './types';

interface Props {
  filters: Filter[];
  filtersApplied: Record<string, string | string[]>;
  onChangeFilter: (id: string, value: string | string[]) => void;
  onResetFilters?: () => void;
}

const Filters = ({ filters, filtersApplied, onChangeFilter, onResetFilters }: Props) => {
  const handleChange = useCallback<Props['onChangeFilter']>(
    (id, value) => {
      onChangeFilter(id, value);
    },
    [onChangeFilter],
  );

  return (
    <FiltersWrapper>
      <FiltersTitleWrapper>
        <FiltersTitle>
          <FiltersIcon />
          <span>Filtros</span>
        </FiltersTitle>

        {onResetFilters && (
          <CircleButton size="small" variant="transparent" onClick={onResetFilters} icon="trash" />
        )}
      </FiltersTitleWrapper>
      <FiltersContainer>
        {filters.map((filter) => {
          const value = filtersApplied[filter.id];
          switch (filter.type) {
            case 'input':
              if (typeof value !== 'string') {
                throw new Error(`The value for a search input must be a string`);
              }

              return (
                <SearchInput {...filter} key={filter.id} value={value} onChange={handleChange} />
              );
            case 'multiselect':
              if (!isStringArray(value)) {
                throw new Error(`The value for a multiselect must be an array of strings`);
              }

              return (
                <Multiselect {...filter} key={filter.id} value={value} onChange={handleChange} />
              );
          }
        })}
      </FiltersContainer>
    </FiltersWrapper>
  );
};

export default Filters;
