import React, { ReactNode } from 'react';

import Header from '../header/header';
import Sidebar from '../sidebar/sidebar';
import { LayoutContainer, LayoutContent, LayoutWrapper } from './layout.styled';

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => (
  <LayoutWrapper>
    <Header />
    <LayoutContainer>
      <Sidebar />
      <LayoutContent>{children}</LayoutContent>
    </LayoutContainer>
  </LayoutWrapper>
);

export default Layout;
