import { useAuth } from '../../providers/auth';
import useFetch from '../useFetch';
import api from './api';
import { Contract, GetContractsCommonOptions } from './types';

interface GetContractsOptions extends GetContractsCommonOptions {
  page?: number;
  pageSize?: number;
}

export interface GetContractsResponse {
  page: number;
  pageSize: number;
  totalCount: number;
  data: Contract[];
}

const useGetContracts = () => {
  const { getAccessToken } = useAuth();
  return useFetch(
    async ({
      search,
      activationReason,
      society,
      version,
      state,
      sortBy = 'id',
      sortDirection = 'ASC',
      page = 0,
      pageSize = 5,
    }: GetContractsOptions) => {
      const accessToken = await getAccessToken();
      const params: Record<string, string> = {};
      if (society?.length) {
        params.society = society.join(',');
      }
      if (activationReason?.length) {
        params.activationReason = activationReason.join(',');
      }
      if (version?.length) {
        params.version = version.join(',');
      }
      if (state?.length) {
        params.state = state.join(',');
      }

      const res = await api.get<GetContractsResponse>('/contract', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          ...params,
          search,
          sortBy,
          sortDirection,
          page,
          pageSize,
        },
      });
      return res.data;
    },
  );
};

export default useGetContracts;
