import React, { ChangeEventHandler, useCallback } from 'react';

import UploadIcon from '../icon/upload';
import {
  FileInputContainer,
  FileInputGroupContainer,
  HiddenInput,
  Label,
  Placeholder,
  UploadIconContainer,
} from './file-input.styled';

export interface FileInputProps {
  accept?: string;
  value?: File;
  onChange?: (file?: File) => void;
}

const FileInput = ({ accept, value, onChange }: FileInputProps) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    const file = e.target.files?.[0];
    onChange?.(file);
  }, []);

  return (
    <>
      <HiddenInput
        id="contracts-csv-input"
        role="dialog"
        type="file"
        accept={accept}
        onChange={handleChange}
      />
      <FileInputGroupContainer htmlFor="contracts-csv-input">
        <Label>Importar CSV</Label>
        <FileInputContainer>
          <UploadIconContainer>
            <UploadIcon />
          </UploadIconContainer>
          <Placeholder>{value ? value.name : 'Seleccionar archivo'}</Placeholder>
        </FileInputContainer>
      </FileInputGroupContainer>
    </>
  );
};

export default FileInput;
