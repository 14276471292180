import React from 'react';

import { ROUTES } from '../../router/routes';
import DeclarationsIcon from '../icon/declarations';
import UsersIcon from '../icon/users';
import {
  IconContainer,
  SidebarExternalLink,
  SidebarLink,
  SidebarList,
  SidebarListItem,
  SidebarNav,
  SidebarWrapper,
} from './sidebar.styled';

const Sidebar = () => (
  <SidebarWrapper>
    <SidebarNav>
      <SidebarList>
        <SidebarListItem>
          <SidebarLink to={ROUTES.CONTRACTS}>
            <IconContainer>
              <UsersIcon />
            </IconContainer>
            <span>Contratos</span>
          </SidebarLink>
        </SidebarListItem>
        <SidebarListItem>
          <SidebarExternalLink href={ROUTES.DDJJ}>
            <IconContainer>
              <DeclarationsIcon />
            </IconContainer>
            <span>DDJJ</span>
          </SidebarExternalLink>
        </SidebarListItem>
      </SidebarList>
    </SidebarNav>
  </SidebarWrapper>
);

export default Sidebar;
