import React, { ReactNode } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import customTheme from './customTheme';
import FontsAndGlobalStyles from './fontsAndGlobalStyles';

interface ThemeProviderProps {
  children: ReactNode;
}

const ThemeProvider = ({ children }: ThemeProviderProps) => (
  <>
    <FontsAndGlobalStyles />
    <StyledThemeProvider theme={customTheme}>{children}</StyledThemeProvider>
  </>
);

export default ThemeProvider;
