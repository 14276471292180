import React from 'react';

import { SvgIcon } from './svgIcon.styled';

const NextIcon = () => (
  <SvgIcon focusable="false" viewBox="0 0 8 12" aria-hidden="true">
    <path
      d="M1.17 1.00005C0.983753 1.18741 0.879211 1.44087 0.879211 1.70505C0.879211 1.96924 0.983753 2.22269 1.17 2.41005L4.71 6.00005L1.17 9.54005C0.983753 9.72741 0.879211 9.98087 0.879211 10.2451C0.879211 10.5092 0.983753 10.7627 1.17 10.9501C1.26297 11.0438 1.37357 11.1182 1.49543 11.1689C1.61729 11.2197 1.74799 11.2458 1.88 11.2458C2.01202 11.2458 2.14272 11.2197 2.26458 11.1689C2.38644 11.1182 2.49704 11.0438 2.59 10.9501L6.83 6.71005C6.92373 6.61709 6.99813 6.50649 7.04889 6.38463C7.09966 6.26277 7.1258 6.13206 7.1258 6.00005C7.1258 5.86804 7.09966 5.73733 7.04889 5.61547C6.99813 5.49362 6.92373 5.38301 6.83 5.29005L2.59 1.00005C2.49704 0.906323 2.38644 0.831929 2.26458 0.78116C2.14272 0.730391 2.01202 0.704252 1.88 0.704252C1.74799 0.704252 1.61729 0.730391 1.49543 0.78116C1.37357 0.831929 1.26297 0.906323 1.17 1.00005Z"
      fill="black"
    />
  </SvgIcon>
);

export default NextIcon;
