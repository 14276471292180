import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ContractDetailPage from '../pages/contract-detail';
import ContractsPage from '../pages/contracts';
import NotFoundPage from '../pages/not-found';
import RootPage from '../pages/root';
import { useAuth } from '../providers/auth';
import { ROUTES } from './routes';

const Router = () => {
  const { isAuthenticated, isLoading, redirectTologin } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    redirectTologin();
    return <></>;
  }

  return (
    <Routes>
      <Route path={ROUTES.ROOT} element={<RootPage />} />
      <Route path={ROUTES.CONTRACTS} element={<ContractsPage />} />
      <Route path={ROUTES.DETAIL} element={<ContractDetailPage />} />

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default Router;
