import ArrowDownIcon from './arrow-down';
import ArrowLeftIcon from './arrow-left';
import ClearIcon from './clear';
import CloseIcon from './close';
import DeclarationsIcon from './declarations';
import FiltersIcon from './filters';
import LogoutIcon from './logout';
import MoreIcon from './more';
import NextIcon from './next';
import PrevIcon from './prev';
import SearchIcon from './search';
import TrashIcon from './trash';
import UploadIcon from './upload';
import UsersIcon from './users';

export const iconsMap = {
  arrowDown: <ArrowDownIcon />,
  arrowLeft: <ArrowLeftIcon />,
  clear: <ClearIcon />,
  close: <CloseIcon />,
  declarations: <DeclarationsIcon />,
  filters: <FiltersIcon />,
  logout: <LogoutIcon />,
  more: <MoreIcon />,
  next: <NextIcon />,
  prev: <PrevIcon />,
  search: <SearchIcon />,
  trash: <TrashIcon />,
  upload: <UploadIcon />,
  users: <UsersIcon />,
};

export type Icons = keyof typeof iconsMap;
