import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import useDebounce from '../../utils/useDebounce';
import { iconsMap } from '../icon';
import { Input, InputWrapper } from './search-input.styled';

export interface SearchInputShape {
  id: string;
  placeholder: string;
}

export interface SearchInputProps extends SearchInputShape {
  onChange: (id: string, value: string) => void;
  value: string;
  debounceTime?: number;
}

const SearchInput = ({
  id,
  onChange,
  value,
  placeholder,
  debounceTime = 500,
}: SearchInputProps) => {
  const [localValue, setLocalValue] = useState(value || '');

  useEffect(() => {
    if (localValue !== value) {
      setLocalValue(value);
    }
  }, [value]);

  const debouncedOnChange = useDebounce(onChange, debounceTime);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setLocalValue(value);
      debouncedOnChange(id, value);
    },
    [onChange],
  );

  return (
    <InputWrapper>
      {iconsMap.search}
      <Input
        id={id}
        type="search"
        autoComplete="off"
        placeholder={placeholder}
        onChange={handleChange}
        value={localValue}
      />
    </InputWrapper>
  );
};
export default SearchInput;
