import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const SidebarWrapper = styled.aside`
  width: 65px;
  min-width: 65px;
  max-width: 248px;
  transition: 0.35s min-width ease-in-out;
  background-color: ${({ theme }) => theme.colors.white};
  height: 100%;
  position: absolute;
  overflow: hidden;
  z-index: 3;

  &:hover {
    min-width: 248px;
    box-shadow: 3px 4px 5px 2px ${({ theme }) => theme.colors.gray.main};
  }

  @media (min-width: 900px) {
    position: relative;
    min-width: 248px;

    &:hover {
      box-shadow: none;
    }
  }
`;

export const SidebarNav = styled.nav`
  display: flex;
  height: 100%;
`;

export const SidebarList = styled.ul`
  padding-top: ${({ theme }) => theme.spacing.xxxlarge};
  list-style-type: none;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SidebarListItem = styled.li<{ disabled?: boolean }>`
  display: flex;
  cursor: default;
  ${({ disabled = false }) => disabled && `opacity: 0.5`};
`;

const linkStyle = css`
  width: 100%;
  text-decoration: none;
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacing.large};
  font-size: ${({ theme }) => theme.spacing.large};
  line-height: 28px;
  border-right: 4px solid ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  gap: 15px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.yellow.light};
    border-right-color: ${({ theme }) => theme.colors.yellow.light};
  }

  &.active {
    background-color: ${({ theme }) => theme.colors.yellow.light};
    border-right-color: ${({ theme }) => theme.colors.yellow.main};
    font-weight: ${({ theme }) => theme.fontWeights.heavy};
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const SidebarLink = styled(NavLink)`
  ${linkStyle}
`;
export const SidebarExternalLink = styled.a`
  ${linkStyle}
`;

export const IconContainer = styled.div`
  width: 30px;
  height: 30px;
  font-size: 30px;
  color: ${({ theme }) => theme.colors.gray.medium};
  transition: color 250ms;
  ${SidebarLink}.active & {
    color: ${({ theme }) => theme.colors.black};
  }
`;
