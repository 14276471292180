import React from 'react';

import { ChipWrapper, ClearButton } from './chip.styled';

interface ChipProps {
  children: string;
  onClear?: () => void;
}

const Chip = ({ children, onClear: clearAction }: ChipProps) => (
  <ChipWrapper>
    {children}
    {clearAction && <ClearButton icon="clear" size="small" onClick={clearAction} />}
  </ChipWrapper>
);

export default Chip;
