import React from 'react';

import { SvgIcon } from './svgIcon.styled';

const PrevIcon = () => (
  <SvgIcon focusable="false" viewBox="0 0 8 12" aria-hidden="true">
    <path
      d="M6.83 10.9999C7.01625 10.8126 7.12079 10.5591 7.12079 10.2949C7.12079 10.0308 7.01625 9.77731 6.83 9.58995L3.29 5.99995L6.83 2.45995C7.01625 2.27259 7.12079 2.01913 7.12079 1.75495C7.12079 1.49076 7.01625 1.23731 6.83 1.04995C6.73703 0.95622 6.62643 0.881826 6.50457 0.831057C6.38271 0.780288 6.25201 0.75415 6.12 0.75415C5.98798 0.75415 5.85728 0.780288 5.73542 0.831057C5.61356 0.881826 5.50296 0.95622 5.41 1.04995L1.17 5.28995C1.07627 5.38291 1.00187 5.49351 0.951105 5.61537C0.900336 5.73723 0.874198 5.86794 0.874198 5.99995C0.874198 6.13196 0.900336 6.26267 0.951105 6.38453C1.00187 6.50638 1.07627 6.61699 1.17 6.70995L5.41 10.9999C5.50296 11.0937 5.61356 11.1681 5.73542 11.2188C5.85728 11.2696 5.98798 11.2957 6.12 11.2957C6.25201 11.2957 6.38271 11.2696 6.50457 11.2188C6.62643 11.1681 6.73703 11.0937 6.83 10.9999Z"
      fill="black"
    />
  </SvgIcon>
);

export default PrevIcon;
