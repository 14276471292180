import styled from 'styled-components';

export const InputWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing.small};
  padding: 0 ${({ theme }) => theme.spacing.xxsmall} 0 ${({ theme }) => theme.spacing.normal};
  font-size: ${({ theme }) => theme.fontSizes.regular};
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray.medium};
  border-radius: ${({ theme }) => theme.radius.small};
`;

export const Input = styled.input`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  appearance: none;
  padding: 0;
  border: 0;
  line-height: 28px;
  width: 100%;

  &:focus-visible {
    outline: 0;
  }
`;
