import React, { memo } from 'react';

import ContractDetailsContractData from './components/contract-details/contract-details-contract-data';
import ContractDetailsPersonalData from './components/contract-details/contract-details-personal-data';
import ContractDetailsTaxData from './components/contract-details/contract-details-tax-data';
import ContractDetailsWorkData from './components/contract-details/contract-details-work-data';

import { useContractDetail } from '../../provider/context';
import { ContractDetailContentContainer } from './contract-detail-content.styled';

const ContractDetailContentProfile = () => {
  const { contractState } = useContractDetail();
  if (!contractState.data) {
    return null;
  }

  return (
    <ContractDetailContentContainer>
      <ContractDetailsPersonalData />
      <ContractDetailsTaxData />
      <ContractDetailsContractData />
      <ContractDetailsWorkData />
    </ContractDetailContentContainer>
  );
};

export default memo(ContractDetailContentProfile);
