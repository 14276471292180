import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

import CircleButton from '../../components/button/circle/circle';

import { MessageType, messageType } from './constants';
import { Message } from './message.styled';
import { MessageSeverity, MessagesContext, MessagesProviderProps } from './types';

const Messages = createContext<MessagesContext | undefined>(undefined);

export const MessagesProvider = ({ children, timeout = 5000 }: MessagesProviderProps) => {
  const [show, setShow] = useState(false);
  const [severity, setSeverity] = useState<MessageSeverity>('error');
  const [message, setMessage] = useState<MessageType>('ERROR');
  const [additionalMessage, setAdditionalMessage] = useState('');
  const timer = useRef<NodeJS.Timeout>();

  const showMessage: MessagesContext['showMessage'] = (severity, type, message = '') => {
    setSeverity(severity);
    setMessage(messageType[type] as MessageType);
    setAdditionalMessage(message);
    setShow(true);
  };

  const closeMessage = useCallback(() => {
    setShow(false);
    clearTimeout(timer.current);
  }, []);

  const timeoutHandler = useCallback(() => {
    timer.current = setTimeout(() => {
      closeMessage();
    }, timeout);
  }, [timeout]);

  useEffect(() => {
    if (show) {
      timeoutHandler();
    }

    return () => {
      clearTimeout(timer.current);
    };
  }, [show, timer]);

  return (
    <Messages.Provider value={{ showMessage }}>
      {children}
      <Message severity={severity} show={show} role="alert">
        <span>
          {message} {additionalMessage}
        </span>
        <CircleButton icon="close" variant="transparent" size="regular" onClick={closeMessage} />
      </Message>
    </Messages.Provider>
  );
};

export const useMessages = (): MessagesContext => {
  const ctx = useContext(Messages);

  if (!ctx) {
    throw new Error('Missing MessagesProvider');
  }

  return ctx;
};
