import React from 'react';

import TableBody, { TableBodyProps } from './table-body';
import TableBodyLoader from './table-body-loader';
import TableHeaders, { TableHeadersProps } from './table-headers';
import { TableWrapper } from './table.styled';

export type TableProps = TableHeadersProps & TableBodyProps;

const Table = ({ columns, data, sortBy, sortDirection, onSortChange }: TableProps) => (
  <TableWrapper>
    <TableHeaders
      columns={columns}
      sortBy={sortBy}
      sortDirection={sortDirection}
      onSortChange={onSortChange}
    />
    {!data ? (
      <TableBodyLoader columnsLength={columns.length} />
    ) : (
      <TableBody data={data} columns={columns} />
    )}
  </TableWrapper>
);

export default React.memo(Table);
