import React, { ReactNode, useCallback, useState } from 'react';

import { iconsMap } from '../../../../../../components/icon';

import { RowDataContainerActionable, RowDataContent, RowDataIcon } from './row-data.styled';

interface Props {
  label: ReactNode;
  children: ReactNode;
}

const RowDataExpandable = ({ label, children }: Props) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = useCallback(() => setExpanded((prev) => !prev), []);

  return (
    <div>
      <RowDataContainerActionable role="switch" onClick={handleToggle}>
        {label}
        <RowDataIcon role="presentation" expanded={expanded}>
          {iconsMap.arrowDown}
        </RowDataIcon>
      </RowDataContainerActionable>
      <RowDataContent expanded={expanded}>{children}</RowDataContent>
    </div>
  );
};

export default RowDataExpandable;
