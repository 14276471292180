import React from 'react';

import Table from '../../../../components/table/table';
import { Columns, SortDirection } from '../../../../components/table/types';

import { Contract } from '../../../../services/api/types';
import {
  formatContractActivationReason,
  formatContractGraduateName,
  formatContractState,
} from '../../utils';

export interface ContractsTableProps {
  data?: Contract[];
  sortBy?: string;
  sortDirection?: SortDirection;
  onSortChange?: (sort: { sortBy: string; sortDirection: SortDirection }) => void;
}

const tableColumns: Columns = [
  { id: 'graduateFullName', header: 'Nombre completo', format: formatContractGraduateName },
  { id: 'graduateEmail', header: 'Email' },
  {
    id: 'activationReason',
    header: 'Motivo de Activación',
    format: formatContractActivationReason,
  },
  { id: 'society', header: 'Sociedad' },
  { id: 'version', header: 'Contrato' },
  { id: 'state', header: 'Estado', format: formatContractState },
];

const ContractsTable = ({ data, sortBy, sortDirection, onSortChange }: ContractsTableProps) => (
  <Table
    columns={tableColumns}
    data={data}
    sortBy={sortBy}
    sortDirection={sortDirection}
    onSortChange={onSortChange}
  />
);

export default ContractsTable;
