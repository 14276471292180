import styled, { css } from 'styled-components';

import { ButtonSize, ButtonVariant } from '../types';

interface ButtonProps {
  onClick?: () => void;
  variant: ButtonVariant;
  size: ButtonSize;
  disabled: boolean;
}

export const CircleButtonStyled = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  appearance: none;
  padding: 0;

  // Size
  ${({ size, theme }) =>
    size === 'regular' &&
    css`
      font-size: ${theme.fontSizes.medium};
      width: ${theme.spacing.xxlarge};
      height: ${theme.spacing.xxlarge};
    `}
  ${({ size, theme }) =>
    size === 'small' &&
    css`
      font-size: ${theme.fontSizes.normal};
      width: ${theme.spacing.regular};
      height: ${theme.spacing.regular};
    `}
  ${({ size, theme }) =>
    size === 'large' &&
    css`
      font-size: ${theme.fontSizes.xlarge};
      width: ${theme.spacing.action};
      height: ${theme.spacing.action};
    `}

  // Background variant
  ${({ variant, theme }) =>
    variant === 'transparent' &&
    css`
      background-color: transparent;
      &:hover {
        background-color: ${theme.colors.gray.light};
      }
    `}
  ${({ variant, theme }) =>
    variant === 'primary' &&
    css`
      background-color: ${theme.colors.yellow.main};
      &:hover {
        background-color: ${theme.colors.yellow.dark};
      }
    `}
  ${({ variant, theme }) =>
    variant === 'secondary' &&
    css`
      background-color: ${theme.colors.gray.main};
      &:hover {
        background-color: ${theme.colors.gray.light};
      }
    `}

  // Disabled state
  &:disabled {
    opacity: 0.25;
    cursor: default;
  }
`;
