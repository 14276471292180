import React, { memo } from 'react';

import Button from '../../../../components/button/regular/regular';

import { useMessages } from '../../../../providers/messages';
import { ContractSort, SortDirection } from '../../../../services/api/types';
import useGetContractsCSV from '../../../../services/api/useGetContractsCSV';

interface Props {
  filtersApplied: Record<string, string | string[]>;
  sort: {
    sortBy: ContractSort;
    sortDirection: SortDirection;
  };
}

const ContractsFiltersDownload = ({ filtersApplied, sort }: Props) => {
  const [downloadContracts, { loading }] = useGetContractsCSV();
  const { showMessage } = useMessages();

  const onClickHandler = () => {
    downloadContracts({ ...filtersApplied, ...sort })
      .then((csv) => {
        const csvContent = `data:text/csv;charset=utf-8,${csv}`;
        const href = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', href);
        link.setAttribute('download', 'contracts.csv');
        link.click();
      })
      .then(() => {
        showMessage('success', 'FILE_DOWNLOAD');
      });
  };

  return (
    <Button size="small" variant="primary" onClick={onClickHandler} disabled={loading}>
      Descargar listado
    </Button>
  );
};

export default memo(ContractsFiltersDownload);
