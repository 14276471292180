import { useCallback, useState } from 'react';

import { useMessages } from '../providers/messages';

export type FetchFunction<Args extends any[] = unknown[], Response = unknown> = (
  ...args: Args
) => Promise<Response>;

export default function useFetch<Args extends any[] = unknown[], Response = unknown>(
  fetchFunction: FetchFunction<Args, Response>,
) {
  const [data, setData] = useState<Response | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const { showMessage } = useMessages();

  const fetch = useCallback(
    (...args: Args) => {
      setLoading(true);
      return fetchFunction(...args)
        .then((res) => {
          setError(null);
          setData(res);
          return res;
        })
        .catch((err) => {
          setError(err);
          showMessage('error', 'ERROR', err.message);
          throw err;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [fetchFunction, setData, setLoading, setError],
  );

  return [fetch, { data, loading, error }] as const;
}
