import styled from 'styled-components';

export const FiltersWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.regular};
`;

export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

export const FiltersTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.xxsmall};
  gap: 8px;
`;

export const FiltersTitle = styled.h3`
  font-weight: ${({ theme }) => theme.fontWeights.heavy};
  line-height: 22px;

  span {
    margin-left: ${({ theme }) => theme.spacing.xxsmall};
  }
`;
