import React, { useCallback, useMemo } from 'react';

import ArrowDownIcon from '../icon/arrow-down';
import { ColumnHeader, SortIconContainer, TableHeader } from './table.styled';
import { Columns, SortDirection } from './types';

export interface TableHeadersProps {
  columns: Columns;
  sortBy?: string;
  sortDirection?: SortDirection;
  onSortChange?: (sort: { sortBy: string; sortDirection: SortDirection }) => void;
}

const TableHeaders = ({ columns, sortBy, sortDirection, onSortChange }: TableHeadersProps) => {
  const IsSortEnbled = useMemo(() => {
    return sortBy !== undefined || sortDirection !== undefined || onSortChange !== undefined;
  }, [sortBy, sortDirection, onSortChange]);

  const handleSortChange = useCallback(
    (columnId: string) => {
      if (!onSortChange) return;

      if (sortBy !== columnId) {
        return onSortChange({
          sortBy: columnId,
          sortDirection: 'ASC',
        });
      }

      onSortChange({
        sortBy: columnId,
        sortDirection: sortDirection === 'ASC' ? 'DESC' : 'ASC',
      });
    },
    [onSortChange, sortBy, sortDirection],
  );

  return (
    <TableHeader>
      <tr>
        {columns.map((column) => (
          <ColumnHeader
            key={column.id}
            isSortEnabled={IsSortEnbled}
            onClick={() => handleSortChange(column.id)}
          >
            {column.header}
            {IsSortEnbled && !column.disableSort && (
              <SortIconContainer show={column.id === sortBy} direction={sortDirection}>
                <ArrowDownIcon />
              </SortIconContainer>
            )}
          </ColumnHeader>
        ))}
      </tr>
    </TableHeader>
  );
};

export default React.memo(TableHeaders);
