import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import Layout from './components/layout/layout';

import { AuthProvider } from './providers/auth';
import { MessagesProvider } from './providers/messages';
import ThemeProvider from './providers/theme';
import BackofficeRoutes from './router';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <ThemeProvider>
        <MessagesProvider>
          <BrowserRouter>
            <Layout>
              <BackofficeRoutes />
            </Layout>
          </BrowserRouter>
        </MessagesProvider>
      </ThemeProvider>
    </AuthProvider>
  </React.StrictMode>,
);
