import Axios from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';

const { REACT_APP_BACKEND_URL, NODE_ENV } = process.env;

const api = Axios.create({
  baseURL: REACT_APP_BACKEND_URL,
});

const mockApi = Axios.create({
  baseURL: '/',
});

export const mockInstance = new AxiosMockAdapter(mockApi, {
  delayResponse: 100,
  onNoMatch: 'throwException',
});

export default NODE_ENV === 'test' ? mockApi : api;
