import styled, { css } from 'styled-components';

import { ButtonColor, ButtonSize, ButtonSpacing, ButtonVariant } from '../types';

interface ButtonProps {
  onClick?: () => void;
  variant: ButtonVariant;
  color: ButtonColor;
  size: ButtonSize;
  spacing: ButtonSpacing;
  disabled: boolean;
}

export const ButtonStyled = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;
  appearance: none;
  border-radius: ${({ theme }) => theme.radius.regular};
  font-weight: ${({ theme }) => theme.fontWeights.black};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.black};
  line-height: 24px;
  transition: background-color 250ms;

  // Size
  ${({ size, theme }) =>
    size === 'small' &&
    css`
      padding: ${theme.spacing.xxsmall};
      font-size: ${({ theme }) => theme.fontSizes.normal};
      line-height: 14px;
    `}
  ${({ size, theme }) =>
    size === 'regular' &&
    css`
      padding: ${theme.spacing.normal} ${theme.spacing.xlarge};
    `}
  ${({ size, theme }) =>
    size === 'large' &&
    css`
      padding: ${theme.spacing.regular} ${theme.spacing.xxlarge};
    `}

  // Background variant
  ${({ variant, theme }) =>
    variant === 'transparent' &&
    css`
      background-color: transparent;
      &:hover {
        background-color: ${theme.colors.gray.light};
      }
    `}
  ${({ variant, theme }) =>
    variant === 'primary' &&
    css`
      background-color: ${theme.colors.yellow.main};
      &:hover {
        background-color: ${theme.colors.yellow.dark};
      }
    `}
  ${({ variant, theme }) =>
    variant === 'secondary' &&
    css`
      background-color: ${theme.colors.gray.main};
      &:hover {
        background-color: ${theme.colors.gray.light};
      }
    `}
  ${({ variant, theme }) =>
    variant === 'plain' &&
    css`
      background-color: transparent;
      color: ${theme.colors.violet.main};
      font-weight: ${({ theme }) => theme.fontWeights.book};
      font-size: ${({ theme }) => theme.fontSizes.regular};
      &:hover {
        color: ${theme.colors.violet.light};
      }
    `}

  ${({ color, theme }) =>
    color === 'error' &&
    css`
      color: ${theme.colors.red.main};
    `};

  // Spacing
  margin: 0;
  ${({ spacing, theme }) => spacing !== 'none' && `margin-${spacing}: ${theme.spacing.normal};`};

  // Disabled state
  &:disabled {
    opacity: 0.25;
    cursor: default;
  }
`;
