import React from 'react';

import { SvgIcon } from './svgIcon.styled';

const TrashIcon = () => (
  <SvgIcon focusable="false" viewBox="-4 -1 18 18" aria-hidden="true">
    <path
      d="M0.833333 13.3333C0.833333 14.25 1.58333 15 2.5 15H9.16667C10.0833 15 10.8333 14.25 10.8333 13.3333V3.33333H0.833333V13.3333ZM2.88333 7.4L4.05833 6.225L5.83333 7.99167L7.6 6.225L8.775 7.4L7.00833 9.16667L8.775 10.9333L7.6 12.1083L5.83333 10.3417L4.06667 12.1083L2.89167 10.9333L4.65833 9.16667L2.88333 7.4ZM8.75 0.833333L7.91667 0H3.75L2.91667 0.833333H0V2.5H11.6667V0.833333H8.75Z"
      fill="#2E2E2E"
    />
  </SvgIcon>
);

export default TrashIcon;
