import { useAuth } from '../../providers/auth';
import useFetch from '../useFetch';
import api from './api';
import { GetContractsCommonOptions } from './types';

const useGetContractsCSV = () => {
  const { getAccessToken } = useAuth();
  return useFetch(
    async ({
      search,
      activationReason,
      society,
      version,
      state,
      sortBy = 'id',
      sortDirection = 'ASC',
    }: GetContractsCommonOptions) => {
      const accessToken = await getAccessToken();
      const params: Record<string, string> = {};
      if (society?.length) {
        params.society = society.join(',');
      }
      if (activationReason?.length) {
        params.activationReason = activationReason.join(',');
      }
      if (version?.length) {
        params.version = version.join(',');
      }
      if (state?.length) {
        params.state = state.join(',');
      }

      const res = await api.get<string>('/contract/csv/download', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          ...params,
          search,
          sortBy,
          sortDirection,
        },
      });
      return res.data;
    },
  );
};

export default useGetContractsCSV;
