import React, { FormEvent, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import Button from '../../../../../../components/button/regular/regular';
import SelectInput from '../../../../../../components/select-input/select-input';

import { useMessages } from '../../../../../../providers/messages';
import { ContractActivationReason } from '../../../../../../services/api/types';
import { formatContractActivationReason } from '../../../../../contracts/utils';
import { useContractDetail } from '../../../../provider/context';
import { ErrorMessage, Form, FormFields } from './edit-drawer.styled';

const activationReasons = [
  { label: 'Graduado', value: 'graduation' },
  { label: 'Baja', value: 'withdraw' },
];

interface Props {
  activationReason: ContractActivationReason;
  onSubmitSuccess: () => void;
}

const EditContractDataForm = ({ activationReason, onSubmitSuccess }: Props) => {
  const { id } = useParams();
  const [reason, setReason] = useState<ContractActivationReason>(activationReason);
  const { editContract, editContractState } = useContractDetail();
  const { showMessage } = useMessages();

  const onChangeActivationReason = useCallback((_id: string, value: string) => {
    setReason(value as ContractActivationReason);
  }, []);

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      await editContract({ activationReason: reason }).then(() => {
        showMessage('success', 'UPDATE_OK');
        onSubmitSuccess();
      });
    },
    [id, reason, onSubmitSuccess],
  );

  return (
    <Form onSubmit={handleSubmit}>
      <FormFields>
        <SelectInput
          id="activationReason"
          label="Motivo de activación"
          options={activationReasons}
          value={formatContractActivationReason('reason', reason)}
          onChange={onChangeActivationReason}
        />
      </FormFields>
      <FormFields>
        {editContractState.error && (
          <ErrorMessage>
            Se produjo un error al actualizar los datos.
            <br />
            {editContractState.error.message}
          </ErrorMessage>
        )}
        <Button type="submit" variant="primary" disabled={editContractState.loading}>
          {editContractState.loading ? 'Actualizando...' : 'Actualizar'}
        </Button>
      </FormFields>
    </Form>
  );
};

export default EditContractDataForm;
