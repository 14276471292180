import React from 'react';

import Chip from '../../../../components/chip/chip';

export interface AppliedFiltersGroupProps {
  id: string;
  value?: string | string[];
  onClear: (id: string, valueToRemove: string) => void;
  format?: (id: string | number, value: string) => string;
}

const AppliedFiltersGroup = ({ id, value, onClear, format }: AppliedFiltersGroupProps) => {
  if (!value) {
    return null;
  }

  if (typeof value === 'string') {
    return <Chip onClear={() => onClear(id, value)}>{format ? format(id, value) : value}</Chip>;
  }

  return (
    <>
      {value.map((value) => (
        <Chip key={`${id}-${value}`} onClear={() => onClear(id, value)}>
          {format ? format(id, value) : value}
        </Chip>
      ))}
    </>
  );
};

export default React.memo(AppliedFiltersGroup);
