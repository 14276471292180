import React, { FormEvent, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import Button from '../../../../../../components/button/regular/regular';
import Pagination from '../../../../../../components/pagination/pagination';
import SelectInput from '../../../../../../components/select-input/select-input';
import TextInput from '../../../../../../components/text-input/text-input';

import { useMessages } from '../../../../../../providers/messages';
import { Graduate } from '../../../../../../services/api/types';
import {
  formatGraduateBillingSystem,
  formatGraduatePaymentMethod,
} from '../../../../../contracts/utils';
import { useContractDetail } from '../../../../provider/context';
import { ErrorMessage, Form, FormFields, FormFieldsVisible } from './edit-drawer.styled';

const billingSystems = [
  { label: 'Stripe', value: 'stripe' },
  { label: 'Colppy', value: 'colppy' },
];
const paymentMethods = [
  { label: 'Tarjeta de crédito', value: 'CreditCard' },
  { label: 'Tarjeta de débito', value: 'DebitCard' },
  { label: 'Cuenta bancaria', value: 'BankAccount' },
  { label: 'Paypal', value: 'Paypal' },
];

interface Props {
  graduate: Graduate;
  onSubmitSuccess: () => void;
}

interface GraduateFields {
  id: Graduate['id'];
  billingSystem: Graduate['billingSystem'];
  paymentMethod: Graduate['paymentMethod'];
  taxId: Graduate['taxId'];
  taxAddressCountry: Graduate['taxAddressCountry'];
  taxAddressZipCode: Graduate['taxAddressZipCode'];
  taxAddressStreetName: Graduate['taxAddressStreetName'];
  taxAddressStreetNumber: Graduate['taxAddressStreetNumber'];
}

const EditFinancialDataForm = ({ onSubmitSuccess, graduate }: Props) => {
  const { id } = useParams();
  const { editGraduate, editGraduateState } = useContractDetail();
  const { showMessage } = useMessages();
  const [graduateData, setGraduateData] = useState<GraduateFields>({
    id: graduate.id,
    billingSystem: graduate.billingSystem,
    paymentMethod: graduate.paymentMethod,
    taxId: graduate.taxId || '',
    taxAddressCountry: graduate.taxAddressCountry || '',
    taxAddressZipCode: graduate.taxAddressZipCode || '',
    taxAddressStreetName: graduate.taxAddressStreetName || '',
    taxAddressStreetNumber: graduate.taxAddressStreetNumber || '',
  });
  const [formPage, setFormPage] = useState(0);

  const onChangeHandler = useCallback(
    (_id: string, value: string) => {
      setGraduateData((prev) => ({ ...prev, [_id]: value }));
    },
    [id],
  );

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      await editGraduate(graduateData).then(() => {
        showMessage('success', 'UPDATE_OK');
        onSubmitSuccess();
      });
    },
    [id, graduateData, onSubmitSuccess],
  );

  const changePageHandler = useCallback(
    ({ page }: { page: number }) => {
      setFormPage(page);
    },
    [id],
  );

  return (
    <Form onSubmit={handleSubmit}>
      <FormFields>
        <FormFieldsVisible show={formPage === 0}>
          <TextInput
            id="taxId"
            placeholder="Tax ID"
            value={graduateData.taxId}
            onChange={onChangeHandler}
            label="Tax ID"
          />
          <SelectInput
            id="paymentMethod"
            label="Método de pago"
            options={paymentMethods}
            value={formatGraduatePaymentMethod('paymentMethod', graduateData.paymentMethod)}
            onChange={onChangeHandler}
          />
          <SelectInput
            id="billingSystem"
            label="Sistema de Facturación"
            options={billingSystems}
            value={formatGraduateBillingSystem('billingSystem', graduateData.billingSystem)}
            onChange={onChangeHandler}
          />
        </FormFieldsVisible>
        <FormFieldsVisible show={formPage === 1}>
          <TextInput
            id="taxAddressCountry"
            placeholder="Argentina"
            value={graduateData.taxAddressCountry}
            onChange={onChangeHandler}
            label="País"
          />
          <TextInput
            id="taxAddressZipCode"
            placeholder="C1406"
            value={graduateData.taxAddressZipCode}
            onChange={onChangeHandler}
            label="Código postal"
          />
          <TextInput
            id="taxAddressStreetName"
            placeholder="Santa Fe"
            value={graduateData.taxAddressStreetName}
            onChange={onChangeHandler}
            label="Calle"
          />
          <TextInput
            id="taxAddressStreetNumber"
            placeholder="2350"
            value={graduateData.taxAddressStreetNumber}
            onChange={onChangeHandler}
            label="Número"
          />
        </FormFieldsVisible>
      </FormFields>
      <FormFields>
        {editGraduateState.error && (
          <ErrorMessage>
            Se produjo un error al actualizar los datos.
            <br />
            {editGraduateState.error.message}
          </ErrorMessage>
        )}
        <Pagination page={formPage} pageSize={1} totalCount={2} onChange={changePageHandler} />
        <Button type="submit" variant="primary" disabled={editGraduateState.loading}>
          {editGraduateState.loading ? 'Actualizando...' : 'Actualizar'}
        </Button>
      </FormFields>
    </Form>
  );
};

export default EditFinancialDataForm;
