import React, { memo, useCallback, useState } from 'react';

import Button from '../../../../../../components/button/regular/regular';
import Drawer from '../../../../../../components/drawer/drawer';

import { useContractDetail } from '../../../../provider/context';
import EditContractDataForm from './edit-contract-data-form';
import { Content, Title } from './edit-drawer.styled';

interface Props {
  title: string;
}

const ContractEditDrawerContractData = ({ title }: Props) => {
  const [opened, setOpened] = useState(false);
  const { contractState } = useContractDetail();

  const toggleOpenedState = useCallback(() => {
    setOpened((currentOpenedState) => !currentOpenedState);
  }, [setOpened]);

  if (!contractState.data) {
    return null;
  }

  return (
    <>
      <Button size="small" variant="plain" onClick={toggleOpenedState}>
        {title}
      </Button>

      <Drawer isOpen={opened} onClose={toggleOpenedState}>
        <Title>{title}</Title>
        <Content>
          <EditContractDataForm
            activationReason={contractState.data.activationReason}
            onSubmitSuccess={toggleOpenedState}
          />
        </Content>
      </Drawer>
    </>
  );
};

export default memo(ContractEditDrawerContractData);
