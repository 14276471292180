import React from 'react';

import { ContractLink } from './contracts-link.styled';

interface Props {
  id: string;
  value: string;
}

export const ContractsLink = ({ id, value }: Props) => {
  return <ContractLink to={id}>{value}</ContractLink>;
};
