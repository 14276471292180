import React from 'react';

import { SvgIcon } from './svgIcon.styled';

const ArrowDownIcon = () => (
  <SvgIcon focusable="false" viewBox="-2 -3 12 12" aria-hidden="true">
    <path
      d="M4.40398 5.40439C4.20427 5.67823 3.79573 5.67823 3.59602 5.40439L0.249374 0.815449C0.0084089 0.485037 0.24441 0.0208327 0.653355 0.0208326L7.34664 0.0208322C7.75559 0.0208321 7.99159 0.485037 7.75062 0.815449L4.40398 5.40439Z"
      fill="#464646"
    />
  </SvgIcon>
);

export default ArrowDownIcon;
