import styled from 'styled-components';

export const Label = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.heavy};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  line-height: 22px;
  color: ${({ theme }) => theme.colors.gray.placeholder};
`;

export const FileInputGroupContainer = styled.label`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xxsmall};
`;

export const FileInputContainer = styled.div`
  width: 100%;
  height: 47px;
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: ${({ theme }) => theme.spacing.normal};
  border: solid 1px ${({ theme }) => theme.colors.gray.medium};
  border-radius: ${({ theme }) => theme.radius.regular};
`;

export const UploadIconContainer = styled.div`
  width: 47px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray.medium};
`;

export const Placeholder = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  line-height: 22px;
  color: ${({ theme }) => theme.colors.gray.placeholder};
`;

export const HiddenInput = styled.input`
  display: none;
`;
