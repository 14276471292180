import React from 'react';

import { Icons, iconsMap } from '../../icon';
import { ButtonSize, ButtonType, ButtonVariant } from '../types';
import { CircleButtonStyled } from './circle.styled';

interface CircleButtonProps {
  onClick?: () => void;
  icon: Icons;
  variant?: ButtonVariant;
  type?: ButtonType;
  size?: ButtonSize;
  disabled?: boolean;
  className?: string;
}

const CircleButton = ({
  onClick,
  icon,
  type = 'button',
  disabled = false,
  size = 'regular',
  variant = 'transparent',
  className,
}: CircleButtonProps) => {
  return (
    <CircleButtonStyled
      onClick={onClick}
      variant={variant}
      aria-label={icon}
      type={type}
      name={icon}
      size={size}
      disabled={disabled}
      className={className}
    >
      {iconsMap[icon]}
    </CircleButtonStyled>
  );
};

export default CircleButton;
