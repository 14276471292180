import styled, { css } from 'styled-components';

import { MessageSeverity } from './types';

interface MessageProps {
  severity: MessageSeverity;
  show: boolean;
}

export const Message = styled.div<MessageProps>`
  display: flex;
  align-items: center;
  column-gap: 10px;
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.snackbar};
  margin: ${({ theme }) => theme.spacing.xlarge};
  padding: ${({ theme }) => theme.spacing.xxsmall};
  padding-left: ${({ theme }) => theme.spacing.normal};
  border-radius: ${({ theme }) => theme.radius.regular};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  box-shadow: 0 2px 6px ${({ theme }) => theme.colors.gray.medium};
  visibility: hidden;
  opacity: 0;
  bottom: -30px;
  transition-duration: 0.2s;
  transition-property: bottom, opacity, visibility;
  transition-timing-function: ease-out;
  color: ${({ theme }) => theme.colors.white};

  ${({ show }) =>
    show &&
    css`
      bottom: 0;
      opacity: 1;
      visibility: visible;
    `}

  // Severity colors
  ${({ severity, theme }) =>
    severity === 'error' &&
    css`
      background-color: ${theme.colors.red.main};
    `}

  ${({ severity, theme }) =>
    severity === 'warning' &&
    css`
      color: ${({ theme }) => theme.colors.black};
      background-color: ${theme.colors.warning};
    `}

  ${({ severity, theme }) =>
    severity === 'success' &&
    css`
      background-color: ${theme.colors.success};
    `}
`;
