import React, { useCallback, useState } from 'react';

import CircleButton from '../../../../components/button/circle/circle';
import Button from '../../../../components/button/regular/regular';
import Drawer from '../../../../components/drawer/drawer';
import FileInput from '../../../../components/file-input/file-input';

import { useMessages } from '../../../../providers/messages';
import useGetContractsCSVTemplate from '../../../../services/api/useGetContractsCSVTemplate';
import usePostContractsCSV, {
  isWrongCSVException,
} from '../../../../services/api/usePostContractsCSV';
import { Content, Expander, Text, Title } from './contracts-csv-upload-sidebar.styled';

const ContractsCSVChargeSideBar = () => {
  const [status, setStatus] = useState<'CLOSED' | 'OPEN' | 'SUCCESS' | 'ERROR'>('CLOSED');
  const [file, setFile] = useState<File>();
  const [getContractsCSVTemplate, getContractsCSVTemplateData] = useGetContractsCSVTemplate();
  const [postContractsCSV, postContractsCSVData] = usePostContractsCSV();
  const { showMessage } = useMessages();

  const handleOpen = useCallback(() => {
    setStatus('OPEN');
  }, [setStatus]);

  const handleClose = useCallback(() => {
    setStatus('CLOSED');
  }, [setStatus]);

  const handleDownloadTemplate = useCallback(() => {
    getContractsCSVTemplate()
      .then((csv) => {
        const csvContent = `data:text/csv;charset=utf-8,${csv}`;
        const href = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', href);
        link.setAttribute('download', 'contracts-upload-template.csv');
        link.click();
      })
      .then(() => {
        showMessage('success', 'FILE_DOWNLOAD');
      });
  }, []);

  const handleFileInputChange = useCallback(
    (file?: File) => {
      setFile(file);
    },
    [setFile],
  );

  const handleUpload = useCallback(() => {
    if (file) {
      postContractsCSV(file)
        .then(() => {
          setStatus('SUCCESS');
          showMessage('success', 'FILE_UPLOAD_OK');
        })
        .catch(() => {
          setStatus('ERROR');
          setFile(undefined);
        });
    }
  }, [file, setStatus]);

  const handleImportAnother = useCallback(() => {
    setFile(undefined);
    setStatus('OPEN');
  }, [setFile, setStatus]);

  const handleDownloadReport = useCallback(() => {
    const error = postContractsCSVData.error;
    if (isWrongCSVException(error)) {
      const csvReport = error.response?.data;
      if (csvReport) {
        const csvContent = `data:text/csv;charset=utf-8,${csvReport}`;
        const href = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', href);
        link.setAttribute('download', 'contracts-upload-errors.csv');
        link.click();
        showMessage('success', 'FILE_DOWNLOAD');
      }
    }
  }, [postContractsCSVData.error]);

  return (
    <>
      <CircleButton icon="more" size="large" variant="primary" onClick={handleOpen} />
      <Drawer isOpen={status !== 'CLOSED'} onClose={handleClose}>
        <Title>Alta de Contratos</Title>
        {status === 'OPEN' && (
          <Content>
            <FileInput accept=".csv" value={file} onChange={handleFileInputChange} />
            <Expander />
            <Button onClick={handleDownloadTemplate} disabled={getContractsCSVTemplateData.loading}>
              Descargar plantilla
            </Button>
            <Button
              variant="primary"
              onClick={handleUpload}
              disabled={!file || postContractsCSVData.loading}
            >
              {postContractsCSVData.loading ? 'Loading...' : 'Importar archivo'}
            </Button>
          </Content>
        )}
        {status === 'SUCCESS' && (
          <Content>
            <Text>
              ¡El listado se cargo con éxito!
              <br /> Vas a poder encontrar a los nuevos Henrys dentro del modulo de graduados
            </Text>
            <Expander />
            <Button onClick={handleClose}>Cerrar</Button>
            <Button variant="primary" onClick={handleImportAnother}>
              Importar otro archivo
            </Button>
          </Content>
        )}
        {status === 'ERROR' && (
          <Content>
            <FileInput accept=".csv" value={file} onChange={handleFileInputChange} />
            <Expander />
            {isWrongCSVException(postContractsCSVData.error) ? (
              <>
                <Text>
                  Se produjo un error en la carga del archivo.
                  <br /> Descarga el reporte y soluciona los confictos para importar correctamente
                  el archivo.
                </Text>
                <Expander />
                <Button color="error" onClick={handleDownloadReport}>
                  Descargar reporte
                </Button>
              </>
            ) : (
              <>
                <Text>
                  Se produjo un error en la carga del archivo.
                  {postContractsCSVData.error.message || postContractsCSVData.error}
                </Text>
                <Expander />
              </>
            )}
            <Button
              variant="primary"
              onClick={handleUpload}
              disabled={!file || postContractsCSVData.loading}
            >
              {postContractsCSVData.loading ? 'Loading...' : 'Importar archivo'}
            </Button>
          </Content>
        )}
      </Drawer>
    </>
  );
};

export default ContractsCSVChargeSideBar;
