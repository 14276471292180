import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const RootList = styled.ul`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing.normal};
`;

export const RootListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) => theme.spacing.large};
  box-shadow: 2px 2px 4px 0px ${({ theme }) => theme.colors.gray.layover};
`;

const linkStyles = css`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.radius.regular};
  padding: ${({ theme }) => `${theme.spacing.xxlarge} 0;`};
  text-decoration: none;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
  width: 140px;
`;

export const ExternalLink = styled.a`
  ${linkStyles}
`;

export const InternalLink = styled(Link)`
  ${linkStyles}
`;
