import styled from 'styled-components';

export const RowData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.normal};
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray.main};
  border-radius: ${({ theme }) => theme.radius.xsmall};
  font-weight: ${({ theme }) => theme.fontWeights.book};
  line-height: 19px;
`;

export const RowDataContainerActionable = styled(RowData)`
  cursor: pointer;
`;

export const RowDataContent = styled.div<{ expanded: boolean }>`
  display: none;

  ${({ expanded = false }) => expanded && 'display: block;'};
`;

export const RowDataIcon = styled.div<{ expanded: boolean }>`
  display: flex;
  align-items: center;
  transition: transform 0.2s ease-in-out;
  ${({ expanded = false }) => expanded && 'transform: rotate(180deg);'};
`;

export const RowDataBlockHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.regular};
`;

export const RowDataBlockTitle = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeights.heavy};
  font-size: ${({ theme }) => theme.fontSizes.large};
`;
