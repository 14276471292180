import styled from 'styled-components';

export const DetailLabel = styled.span`
  color: ${({ theme }) => theme.colors.gray.medium};
`;

export const DetailLabelStrong = styled(DetailLabel)`
  font-weight: ${({ theme }) => theme.fontWeights.heavy};
`;

export const DetailLabelIndented = styled(DetailLabel)`
  padding-left: ${({ theme }) => theme.spacing.xxxlarge};
`;

export const DetailValue = styled.span<{ variant?: 'success' | 'warning' | 'error' }>`
  color: ${({ variant = 'default', theme }) => {
    switch (variant) {
      case 'success':
        return theme.colors.success;

      case 'warning':
        return theme.colors.warning;

      case 'error':
        return theme.colors.red.main;

      default:
        return theme.colors.black;
    }
  }};
`;
