import React from 'react';

import { SvgIcon } from './svgIcon.styled';

const ArrowLeftIcon = () => (
  <SvgIcon focusable="false" viewBox="0 0 14 18" aria-hidden="true">
    <path
      d="M4.05334 7L8.77334 2.28C9.02167 2.03018 9.16106 1.69225 9.16106 1.34C9.16106 0.98775 9.02167 0.649814 8.77334 0.399998C8.64939 0.275026 8.50192 0.175834 8.33944 0.108142C8.17696 0.0404506 8.00269 0.00559998 7.82667 0.00559998C7.65066 0.00559998 7.47638 0.0404506 7.3139 0.108142C7.15142 0.175834 7.00396 0.275026 6.88001 0.399998L1.22667 6.05333C1.1017 6.17728 1.00251 6.32475 0.934818 6.48723C0.867127 6.64971 0.832275 6.82398 0.832275 7C0.832275 7.17601 0.867127 7.35029 0.934818 7.51277C1.00251 7.67525 1.1017 7.82271 1.22667 7.94666L6.88001 13.6667C7.00459 13.7902 7.15235 13.888 7.3148 13.9544C7.47725 14.0207 7.6512 14.0543 7.82667 14.0533C8.00215 14.0543 8.1761 14.0207 8.33855 13.9544C8.501 13.888 8.64875 13.7902 8.77334 13.6667C9.02167 13.4168 9.16106 13.0789 9.16106 12.7267C9.16106 12.3744 9.02167 12.0365 8.77334 11.7867L4.05334 7Z"
      fill="black"
    />
  </SvgIcon>
);

export default ArrowLeftIcon;
